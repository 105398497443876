import { backgroundClass, textClass } from './index.css';

const ByZeroEx = () => (
  <svg width="60" height="29" viewBox="0 0 60 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8.67773C0 3.88515 3.84595 0 8.59018 0L51.4098 0.00389254C56.154 0.00389254 60 3.88904 60 8.68162V19.3842C60 24.1767 56.154 28.0619 51.4098 28.0619L8.59018 28.058C3.84595 28.058 0 24.1728 0 19.3803V8.67773Z"
      className={backgroundClass}
    />
    <g className={textClass}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3993 7.61836C37.5708 7.03262 36.5801 6.73975 35.4273 6.73975C34.2745 6.73975 33.2838 7.03262 32.4553 7.61836C31.6267 8.1919 31.0023 8.9851 30.582 9.99795C30.1617 11.0108 29.9516 12.1701 29.9516 13.4758C29.9516 14.7815 30.1617 15.9408 30.582 16.9537C31.0023 17.9665 31.6267 18.7658 32.4553 19.3515C33.2838 19.9251 34.2745 20.2119 35.4273 20.2119C36.5801 20.2119 37.5708 19.9251 38.3993 19.3515C39.2279 18.7658 39.8523 17.9665 40.2726 16.9537C40.6929 15.9408 40.9031 14.7815 40.9031 13.4758C40.9031 12.1701 40.6929 11.0108 40.2726 9.99795C39.8523 8.9851 39.2279 8.1919 38.3993 7.61836ZM36.9876 9.8742C36.5851 9.44417 36.065 9.22916 35.4273 9.22916C34.5747 9.22916 33.9263 9.61355 33.482 10.3823C33.0497 11.1511 32.8335 12.1823 32.8335 13.4758C32.8335 14.2878 32.9187 14.9965 33.0891 15.6017L36.9876 9.8742ZM33.8555 17.0759C34.265 17.5069 34.7889 17.7224 35.4273 17.7224C36.2799 17.7224 36.9223 17.338 37.3546 16.5693C37.7989 15.8005 38.0211 14.7693 38.0211 13.4758C38.0211 12.6613 37.933 11.9508 37.7568 11.3444L33.8555 17.0759Z"
        fill="currentColor"
      />
      <path
        d="M44.3231 14.8669L40.9368 19.919H44.179L45.7641 17.0635C45.8842 16.8682 45.9563 16.612 45.9803 16.2947C45.9923 16.1727 46.0163 16.0811 46.0523 16.0201C46.0883 15.9469 46.1604 15.9103 46.2685 15.9103C46.4366 15.9103 46.5327 16.0201 46.5567 16.2398V16.2947C46.5807 16.612 46.6527 16.8682 46.7728 17.0635L48.3579 19.919H51.6001L48.2138 14.8669L51.3119 10.2542H48.0697L46.7728 12.6521C46.6287 12.9084 46.5567 13.1646 46.5567 13.4209C46.5567 13.5307 46.5326 13.6222 46.4846 13.6955C46.4486 13.7687 46.3765 13.8053 46.2685 13.8053C46.1604 13.8053 46.0823 13.7687 46.0343 13.6955C45.9983 13.6222 45.9803 13.5307 45.9803 13.4209C45.9803 13.1646 45.9082 12.9084 45.7641 12.6521L44.4672 10.2542H41.225L44.3231 14.8669Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88768 11.2512C9.93085 11.1087 9.95243 10.9552 9.95243 10.7907V7.30414H7.88037V19.1454H9.95243V18.7671C9.95243 18.482 9.87689 18.186 9.7258 17.879L9.70961 17.8297C9.66644 17.742 9.64486 17.6707 9.64486 17.6159C9.64486 17.5611 9.68263 17.5172 9.75817 17.4843C9.81213 17.4624 9.8553 17.4679 9.88768 17.5008C9.93085 17.5227 9.96322 17.5611 9.98481 17.6159L10.001 17.6488C10.2708 18.1531 10.6323 18.5533 11.0856 18.8493C11.5496 19.1344 12.0892 19.2769 12.7044 19.2769C13.4274 19.2769 14.0696 19.0796 14.6307 18.6849C15.2027 18.2902 15.6452 17.7529 15.9582 17.0732C16.2711 16.3934 16.4276 15.6369 16.4276 14.8036C16.4276 13.9703 16.2711 13.2138 15.9582 12.534C15.6452 11.8542 15.2027 11.317 14.6307 10.9223C14.0696 10.5276 13.4274 10.3302 12.7044 10.3302C12.0784 10.3302 11.5388 10.4782 11.0856 10.7743C10.6431 11.0593 10.2816 11.4486 10.001 11.942L9.98481 11.9584V11.9748C9.94164 12.0406 9.89847 12.0954 9.8553 12.1393C9.82293 12.1722 9.77436 12.1722 9.70961 12.1393C9.64486 12.1064 9.61248 12.0626 9.61248 12.0077C9.62327 11.942 9.65025 11.8597 9.69342 11.761C9.70421 11.7501 9.70961 11.7391 9.70961 11.7282C9.70961 11.7172 9.71501 11.7062 9.7258 11.6953C9.80134 11.5418 9.8553 11.3937 9.88768 11.2512ZM13.4005 17.0238C13.0767 17.2541 12.6828 17.3692 12.2187 17.3692C11.7763 17.3692 11.3824 17.2541 11.037 17.0238C10.6917 16.7936 10.4219 16.4811 10.2276 16.0864C10.0442 15.6917 9.95243 15.2641 9.95243 14.8036C9.95243 14.3431 10.0442 13.9155 10.2276 13.5208C10.4219 13.1261 10.6917 12.8136 11.037 12.5834C11.3824 12.3531 11.7763 12.238 12.2187 12.238C12.6828 12.238 13.0767 12.3531 13.4005 12.5834C13.7242 12.8136 13.9616 13.1261 14.1127 13.5208C14.2746 13.9045 14.3556 14.3321 14.3556 14.8036C14.3556 15.275 14.2746 15.7081 14.1127 16.1028C13.9616 16.4866 13.7242 16.7936 13.4005 17.0238Z"
        fill="currentColor"
      />
      <path
        d="M19.0919 10.4618H16.8904L20.2251 19.2605L20.047 19.7868C19.9931 19.9512 19.9337 20.0773 19.8689 20.165C19.8042 20.2527 19.7233 20.313 19.6261 20.3459C19.529 20.3898 19.3941 20.4227 19.2214 20.4446C19.1459 20.4556 19.0595 20.4611 18.9624 20.4611H17.635V22.303H19.189C19.6855 22.303 20.1118 22.2318 20.4679 22.0892C20.824 21.9467 21.1316 21.7164 21.3906 21.3985C21.6496 21.0805 21.8762 20.6639 22.0705 20.1486L25.6966 10.4618H23.495L21.6658 16.0699C21.5903 16.3002 21.5417 16.5633 21.5201 16.8594V17.0567C21.5201 17.1444 21.5039 17.2157 21.4716 17.2705C21.45 17.3144 21.4014 17.3363 21.3259 17.3363C21.2503 17.3363 21.1964 17.3089 21.164 17.2541C21.1424 17.1992 21.1316 17.1335 21.1316 17.0567C21.1316 16.8594 21.1208 16.6839 21.0992 16.5304C21.0884 16.3769 21.0561 16.2234 21.0021 16.0699L19.0919 10.4618Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ByZeroEx;
