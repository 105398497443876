import {
  useWallets,
  useSolanaWallets,
  type ConnectedWallet,
  type ConnectedSolanaWallet,
  useConnectWallet,
  BaseConnectedWalletType,
} from '@privy-io/react-auth';
import { useMemo, useState } from 'react';
import { useDisconnect } from 'wagmi';

type MatchaConnectedWallet<T extends ConnectedWallet | ConnectedSolanaWallet> = Omit<
  T,
  'disconnect'
> & {
  /** this function combines the native `disconnect` function of privy with the disconnect shim
   * functionality via local storage
   */
  disconnect: () => void;
};

type ReturnType = {
  ethereumWallet: MatchaConnectedWallet<ConnectedWallet> | undefined;
  solanaWallet: MatchaConnectedWallet<ConnectedSolanaWallet> | undefined;
  isConnected: boolean;
};

const matchaUsedEvmWalletKey = 'matcha.usedEvmWallet';
const matchaUsedSolanaWalletKey = 'matcha.usedSolanaWallet';

function onSuccess({ wallet }: { wallet: BaseConnectedWalletType }) {
  if (wallet.type === 'ethereum') {
    localStorage.setItem(matchaUsedEvmWalletKey, wallet.meta.name);
  }
  if (wallet.type === 'solana') {
    localStorage.setItem(matchaUsedSolanaWalletKey, wallet.meta.name);
  }
}

/**
 * A custom React hook that aggregates and filters wallet connections from Privy for both Ethereum and Solana networks.
 *
 * This hook retrieves Ethereum wallets via `useWallets` and Solana wallets via `useSolanaWallets`,
 * then filters the Ethereum wallets using the list of active connector names from the `useDisconnect` hook.
 * It constructs an array of active wallets by selecting the first available Solana wallet and the first
 * Ethereum wallet that match an active connector.
 *
 * @returns An object containing:
 *  - `solanaWallet`: The currently connected solana wallet or `undefined` if none is connected
 *  - `ethereumWallet`: The currently connected ethereum wallet or `undefined` if none is connected
 */
export function useMatchaWallets(): ReturnType {
  const { disconnect, connectors } = useDisconnect();
  const { wallets: ethereumWallets } = useWallets();
  const { wallets: solanaWallets } = useSolanaWallets();

  useConnectWallet({
    onSuccess,
  });

  // used to forcefully refresh the hook if a soft-disconnect happens
  const [version, setVersion] = useState(0);
  const { solanaWallet, ethereumWallet, isConnected } = useMemo<ReturnType>(() => {
    if (typeof localStorage === 'undefined')
      return { solanaWallet: undefined, ethereumWallet: undefined, isConnected: false };
    const [solanaWallet] = solanaWallets.filter((wallet) => {
      return wallet.meta.name === localStorage.getItem(matchaUsedSolanaWalletKey);
    });

    const connectorNames = connectors.map((connector) => connector.name);
    const [ethereumWallet] = ethereumWallets.filter(
      (wallet) =>
        connectorNames.includes(wallet.meta.name) &&
        wallet.meta.name === localStorage.getItem(matchaUsedEvmWalletKey),
    );

    const isMultiChainWallet =
      ethereumWallet &&
      solanaWallet &&
      ethereumWallet.walletClientType === solanaWallet.walletClientType;

    if (solanaWallet) {
      // monkeypatch
      const originalFunction = solanaWallet.disconnect;
      solanaWallet.disconnect = () => {
        // check if there is a soft-disconnected ethereum wallet
        for (const evmWallet of ethereumWallets) {
          if (
            evmWallet.walletClientType === solanaWallet.walletClientType &&
            evmWallet.meta.name !== localStorage.getItem(matchaUsedEvmWalletKey)
          ) {
            disconnect({
              connector: connectors.find((connector) => connector.name === evmWallet.meta.name),
            });
          }
        }
        localStorage.removeItem(matchaUsedSolanaWalletKey);
        originalFunction();
      };
    }

    if (ethereumWallet) {
      // monkeypatch
      const originalFunction = ethereumWallet.disconnect;
      ethereumWallet.disconnect = () => {
        localStorage.removeItem(matchaUsedEvmWalletKey);
        if (isMultiChainWallet) {
          // soft-disconnect the wallet to preserve the session
          setVersion(version + 1);
        } else {
          disconnect({
            connector: connectors.find((connector) => connector.name === ethereumWallet.meta.name),
          });
        }
        originalFunction();
      };
    }

    return { solanaWallet, ethereumWallet, isConnected: !!solanaWallet || !!ethereumWallet };
  }, [ethereumWallets, solanaWallets, connectors, disconnect, version]);

  return {
    solanaWallet,
    ethereumWallet,
    isConnected,
  };
}
