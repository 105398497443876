import { Inter } from 'next/font/google';
import localFont from 'next/font/local';
import React from 'react';

export const interFont = Inter({ subsets: ['latin'] });

// note: uncomment font files if we need them
// commented out fonts are not used in the app
export const gilroyFont = localFont({
  src: [
    // {
    //   path: './local-fonts/Gilroy-Thin.woff2',
    //   weight: '100',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-ThinItalic.woff2',
    //   weight: '100',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-UltraLight.woff2',
    //   weight: '200',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-UltraLightItalic.woff2',
    //   weight: '200',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-Light.woff2',
    //   weight: '300',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-LightItalic.woff2',
    //   weight: '300',
    //   style: 'normal',
    // },
    {
      path: './local-fonts/Gilroy-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './local-fonts/Gilroy-RegularItalic.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './local-fonts/Gilroy-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './local-fonts/Gilroy-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: './local-fonts/Gilroy-SemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: './local-fonts/Gilroy-SemiBoldItalic.woff2',
      weight: '600',
      style: 'italic',
    },
    {
      path: './local-fonts/Gilroy-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: './local-fonts/Gilroy-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
    // {
    //   path: './local-fonts/Gilroy-ExtraBold.woff2',
    //   weight: '800',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-ExtraBoldItalic.woff2',
    //   weight: '800',
    //   style: 'italic',
    // },
    // {
    //   path: './local-fonts/Gilroy-Heavy.woff2',
    //   weight: '900',
    //   style: 'normal',
    // },
    // {
    //   path: './local-fonts/Gilroy-HeavyItalic.woff2',
    //   weight: '900',
    //   style: 'italic',
    // },
  ],
});

export const Fonts: React.FC = () => {
  return (
    <style jsx global>{`
      :root {
        --font-family-heading: ${gilroyFont.style.fontFamily};
        --font-family-body: ${interFont.style.fontFamily};
      }
    `}</style>
  );
};
