// This file contains the Sentry base configuration shared across all environments.
const baseConfig = {
  // debug mode
  debug: false,
  // data source name
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // transaction sample rate
  tracesSampleRate: 0.5,
};

export default baseConfig;
