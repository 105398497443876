import { Result } from '@/types';
import queryString from 'query-string';

import { solana } from '@/constants/chain';

import ENV from '../../environment/server';
import type {
  TokenResult,
  TokensByAddressQuery,
  TokensByAddressesQuery,
  TokensByNameOrSymbolQuery,
  TokensData,
  TokensResponse,
} from './token-registry.types';

const getDefaultHeaders = (apiVersion: string | undefined) => {
  if (!ENV.ZEROEX_API_KEY) throw new Error('ZEROEX_API_KEY is required'); // enforce defining the API key
  return {
    '0x-api-key': ENV.ZEROEX_API_KEY,
    ...(apiVersion ? { '0x-version': apiVersion } : {}),
  };
};

/** Get the Token Registry base URL and path */
const URL_BASE = `https://${ENV.ZEROEX_API_BASE_URL}`;
const TOKEN_REGISTRY_PATH = 'tokens';
const TOKEN_REGISTRY_VERSION = 'v1';

const getUrl = (path?: string, queryParams?: { [key: string]: any }) => {
  const query = queryString.stringify(queryParams || {}, { arrayFormat: 'comma' });

  return `${URL_BASE}/${TOKEN_REGISTRY_PATH}/${TOKEN_REGISTRY_VERSION}${path ?? ''}${queryParams ? `?${query}` : ''}`;
};

const prepareTokenResultFields = (token: TokenResult): TokenResult => {
  token.chainId = Number(token.chainId); // API returns a string but we want a number
  token.decimals = Number(token.decimals); // API returns a string but we want a number
  if (token.name === null) token.name = 'Unknown Token';
  if (token.symbol === null) token.symbol = 'UNKNOWN';
  return token;
};

/**
 * Fetch a single token by address
 */
export const fetchTokensByAddress = async ({
  address,
  apiVersion,
  ...searchQuery
}: TokensByAddressQuery): Promise<TokensResponse> => {
  try {
    const response = await fetch(getUrl(`/address/${address}`, searchQuery), {
      headers: getDefaultHeaders(apiVersion),
    });

    let token = await response.json();
    token = prepareTokenResultFields(token);
    return token;
  } catch (err: any) {
    throw err;
  }
};

export class TokenResultError extends Error {
  public code: string;

  constructor(message = '', code = '') {
    super(message);
    this.code = code;
    this.name = 'TokenResultError';
  }
}

/**
 * Fetch many tokens by their addresses
 * (maximum amount of tokens to fetch is 100 {@link https://github.com/0xProject/0x-labs/blob/b75abc2f80acc25e6cbe24cc044e762eedbdd5ae/packages/token-api-interface/src/index.ts#L14C14-L14C35})
 */
export const fetchTokensByAddresses = async ({
  addresses,
  apiVersion,
  ...searchQuery
}: TokensByAddressesQuery): Promise<Result<TokensData>> => {
  if (addresses.length === 0) {
    return {
      type: 'success',
      data: { data: [], resultCount: 0 },
    };
  }
  try {
    // If the chainId is Solana, return an empty result because the Token API doesn't support Solana
    if (
      Array.isArray(searchQuery.chainId) &&
      searchQuery.chainId.length === 1 &&
      searchQuery.chainId.includes(solana.id.toString())
    ) {
      return {
        type: 'success',
        data: { data: [], resultCount: 0 },
      };
    }

    let url = getUrl(`/address/${addresses.join(',')}`, searchQuery)
      .replace(`${solana.id},`, '')
      .replace(solana.id.toString(), ''); // Token API doesn't support Solana—so remove the SOL chain id for now...

    if (url[url.length - 1] === '?') {
      url = url.slice(0, -1);
    }

    const response = await fetch(url, {
      headers: getDefaultHeaders(apiVersion),
    });

    let json = await response.json();
    if (!('data' in json)) {
      console.debug('tokens by address results error:', json.code, json.message);
      throw new TokenResultError('Error while retrieving tokens by address', json.code);
    }
    json.data = json.data.map((token: TokenResult) => prepareTokenResultFields(token));

    return {
      type: 'success',
      data: json,
    };
  } catch (err: unknown) {
    if (err instanceof Error) {
      return {
        type: 'error',
        error: err,
      };
    }

    return {
      type: 'error',
      error: new Error(`Unknown error: ${err}`),
    };
  }
};

/**
 * Search for tokens by name or symbol
 */
export const fetchTokensByNameOrSymbol = async ({
  query: symbolOrName,
  apiVersion,
  ...searchQuery
}: TokensByNameOrSymbolQuery): Promise<Result<TokensData>> => {
  try {
    // If the chainId is Solana, return an empty result because the Token API doesn't support Solana
    if (
      Array.isArray(searchQuery.chainId) &&
      searchQuery.chainId.length === 1 &&
      searchQuery.chainId.includes(solana.id.toString())
    ) {
      return {
        type: 'success',
        data: { data: [], resultCount: 0 },
      };
    }

    const url = getUrl(`/symbolOrName/${encodeURIComponent(symbolOrName)}`, searchQuery)
      .replace(`${solana.id},`, '')
      .replace(solana.id.toString(), ''); // Token API doesn't support Solana—so remove the SOL chain id for now...

    const response = await fetch(url, {
      headers: getDefaultHeaders(apiVersion),
    });

    const json = await response.json();
    if (!response.ok) {
      return {
        type: 'error',
        error: new TokenResultError(json.message, json.code),
      };
    }
    json.data = json.data.map((token: TokenResult) => prepareTokenResultFields(token));

    return {
      type: 'success',
      data: json,
    };
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.error(err.message);
      return {
        type: 'error',
        error: err,
      };
    }

    return {
      type: 'error',
      error: new Error(`Unknown error: ${err}`),
    };
  }
};
