import { nanoid } from 'nanoid';
import { StateCreator } from 'zustand/vanilla';

import { trackError } from '../../../utils/errors';

/**
 * Options for the syncTabs middleware
 */
type SyncTabsOptions<T> = {
  /**
   * The name of the channel to use for broadcasting state updates.
   * This should be unique to the application.
   */
  channelName: string;
  /**
   * A function for selectively synchronizing state.
   * This is useful if you want to synchronize only a subset of the state.
   */
  partialize?: (state: T) => Partial<T>;
};

type SyncTabsImpl = <T>(
  /**
   * The store initializer function.
   */
  storeInitializer: StateCreator<T, any, any>,
  /**
   * Options for the syncTabs middleware.
   */
  options: SyncTabsOptions<T>,
) => StateCreator<T, any, any>;

/**
 * Middleware for syncing state across tabs.
 *
 * Caveat:
 * This is a very minimal implementation that shares all changes across all tabs.
 * It does currently not care which tab is the initiator; we could theoretically get into situations
 * where tabs in the background might fetch data simultaneously, creating a race condition.
 * If this becomes an issue, we will add functionality to only allow unidirectional syncing from
 * the currently active "master" tab to "replica" tabs.
 */
export const syncTabs: SyncTabsImpl =
  (
    /**
     * The store initializer function.
     */
    storeInitializer,
    /**
     * Options for the syncTabs middleware.
     */
    { channelName, partialize },
  ) =>
  (set, get, api) => {
    const tabId = nanoid();
    let isActive = true;

    type T = ReturnType<typeof get>;

    // create channel if supported
    let channel: Pick<BroadcastChannel, 'postMessage' | 'addEventListener' | 'close'> = {
      postMessage: () => {},
      addEventListener: () => {},
      close: () => {},
    };
    if (typeof BroadcastChannel !== 'undefined') {
      channel = new BroadcastChannel(channelName);
    } else {
      console.warn('BroadcastChannel is not supported in this environment');
    }

    let isUpdating = false;

    // enhance zustand set method to facilitate state synchronization
    const wrappedSet = (partial: T | ((state: T) => T | Partial<T>), replace?: boolean) => {
      let stateUpdate: Partial<T> = {};

      // isUpdating creates a lock on the state update to prevent infinite loops
      if (!isUpdating) {
        if (partialize) {
          set(partial, replace);
          stateUpdate = partialize(get());
        } else {
          stateUpdate =
            typeof partial === 'function'
              ? (partial as (state: T) => T | Partial<T>)(get())
              : partial;
          set(partial, replace);
        }
        if (isActive) {
          try {
            channel.postMessage({ tabId, stateUpdate });
          } catch (error) {
            // ensuring we don't crash the app
            console.warn('Error posting message to broadcast channel', error);
            trackError(error as Error);
          }
        }
      }
    };

    channel.addEventListener(
      'message',
      (event: MessageEvent<{ tabId: string; stateUpdate: T }>) => {
        if (event.data.tabId !== tabId) {
          isUpdating = true;
          set(() => event.data.stateUpdate);
          isUpdating = false;
        }
      },
    );

    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        if (isActive) {
          isActive = false;
          channel.close();
        }
      });
    }

    return storeInitializer(wrappedSet, get, api);
  };
