/**
 * An error that is thrown on the client.
 *
 * @param message - The error message.
 * @param type - The error type for categorization.
 * @param errorOptions - Additional options for the error (optional).
 * @param originalError - The original error (optional).
 * @remarks Error type should be in `snake_case`.
 */
export class ClientError extends Error {
  public name = 'ClientError';
  public type: string;

  constructor(message: string, type: string, errorOptions?: ErrorOptions) {
    super(message, errorOptions);
    this.type = type;
  }
}

/**
 * Error thrown when there are insufficient funds
 */
export class ClientBalanceError extends ClientError {
  public name = 'ClientBalanceError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'balance_error', errorOptions);
  }
}

/**
 * Error thrown when missing API keys, project IDs, or other required
 * configuration
 */
export class ClientConfigurationError extends ClientError {
  public name = 'ClientConfigurationError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'configuration_error', errorOptions);
  }
}

/**
 * Error thrown when something goes wrong in data processing or validation
 */
export class ClientDataError extends ClientError {
  public name = 'ClientDataError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'configuration_error', errorOptions);
  }
}

/**
 * Error thrown when invalid user inputs (chainId, address, etc..)
 */
export class ClientInputError extends ClientError {
  public name = 'ClientInputError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'input_error', errorOptions);
  }
}

/**
 * Error thrown when fetching gas info, fee data, quotes, or other
 * network-dependent data
 */
export class ClientNetworkError extends ClientError {
  public name = 'ClientNetworkError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'network_error', errorOptions);
  }
}

/**
 * Error thrown when tokens require approval, are blocked, or there are issues
 * with wrapped tokens
 */
export class ClientTokenError extends ClientError {
  public name = 'ClientTokenError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'token_error', errorOptions);
  }
}

/**
 * Error thrown when building or submitting a transaction
 */
export class ClientTransactionError extends ClientError {
  public name = 'ClientTransactionError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'transaction_error', errorOptions);
  }
}

/**
 * Error thrown around a users wallet (connection issues, missing account,
 * etc...)
 */
export class ClientWalletError extends ClientError {
  public name = 'ClientWalletError';

  constructor(message: string, errorOptions?: ErrorOptions) {
    super(message, 'wallet_error', errorOptions);
  }
}
