/**
 * Retrieves the keys of an object as an array of the object's key type.
 *
 * This utility function is a type-safe way to get the keys of an object,
 * ensuring that the returned array is typed with the keys of the object.
 *
 * @typeParam T - The type of the object whose keys are to be retrieved.
 * @param obj - The object from which to retrieve the keys.
 * @returns An array of keys of the object, typed as (keyof T)[]
 *
 * @example
 * const user = \{ name: 'Alice', age: 30 \};
 * const userKeys = keys(user); // Type: ('name' | 'age')[]
 */
export function keys<T extends Object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}
