import { SVGProps } from 'react';

export default function LogoCup(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7918 2.6688C17.7482 2.756 17.6437 2.79325 17.5547 2.75328C16.2312 2.15103 14.6226 1.94755 13.5653 1.94755C9.76389 1.94755 5.96159 3.80154 5.96159 6.84913C5.96159 8.48329 7.18694 9.86584 8.97818 9.86584C10.9574 9.86584 12.4344 8.42062 13.628 7.10075C14.6962 5.90715 15.9533 4.58728 17.7436 4.58728C19.692 4.58728 21.1054 6.0325 21.1054 7.98096C21.1054 11.0603 17.3976 14.5167 12.2763 14.5167C7.15514 14.5167 3.44822 11.7825 3.44822 7.98096C3.44822 4.43013 7.65836 1.22539 12.2773 1.22539C14.6344 1.22539 16.4093 1.71955 17.7155 2.42808C17.8018 2.47532 17.8354 2.5816 17.7918 2.66971V2.6688ZM12.2773 0C5.96159 0 0.808594 5.1532 0.808594 11.4691C0.808594 17.785 5.96159 23.0009 12.2773 23.0009C18.5929 23.0009 23.8086 17.8477 23.8086 11.4691C23.8086 5.09052 18.6547 0 12.2773 0Z"
        fill="currentColor"
      />
    </svg>
  );
}
