import { primitiveMapping, rawPrimitiveContract } from '@/styles/primitives.css';
import { darkMapping, lightMapping, rawColorContract } from '@/styles/tokens.css';

import { keys } from '@/utils/keys';

interface RGB {
  /** Red hex value */
  r: number;
  /** Green hex value */
  g: number;
  /** Blue hex value */
  b: number;
}

interface HSL {
  /** Hue value in degrees (0-360) */
  h: number;
  /** Saturation value in percentage (0-100) */
  s: number;
  /** Lightness value in percentage (0-100) */
  l: number;
}

/**
 * Function to convert a hex string to RGB values.
 * @param str - Hex color string
 */
export const hexToRGB = (str: string): RGB => {
  const hex = str.startsWith('#') ? str.substring(1) : str;

  if (hex.length !== 6) throw new Error('hexToRGB: Invalid hex string');

  return {
    r: parseInt(hex.slice(0, 2), 16),
    g: parseInt(hex.slice(2, 4), 16),
    b: parseInt(hex.slice(4, 6), 16),
  };
};

/**
 * Function to convert a hex string to HSL values
 * @param str - Hex color string
 */
export function hexToHSL(str: string): HSL {
  // Remove the hash if present
  const hex = str.startsWith('#') ? str.substring(1) : str;

  // Convert to RGB first
  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}
/**
 * Convert HSL back into an RGBA string with optional alpha.
 */
export function hslToRGBA(h: number, s: number, l: number, alpha = 1): string {
  // Must clamp values
  const _h = ((h % 360) + 360) % 360;
  const _s = Math.min(Math.max(s, 0) / 100, 1);
  const _l = Math.min(Math.max(l, 0) / 100, 1);

  const c = (1 - Math.abs(2 * _l - 1)) * _s;
  const x = c * (1 - Math.abs(((_h / 60) % 2) - 1));
  const m = _l - c / 2;

  let r = 0,
    g = 0,
    b = 0;
  if (_h < 60) {
    r = c;
    g = x;
  } else if (_h < 120) {
    r = x;
    g = c;
  } else if (_h < 180) {
    g = c;
    b = x;
  } else if (_h < 240) {
    g = x;
    b = c;
  } else if (_h < 300) {
    r = x;
    b = c;
  } else {
    r = c;
    b = x;
  }

  const R = Math.round((r + m) * 255);
  const G = Math.round((g + m) * 255);
  const B = Math.round((b + m) * 255);

  return `rgba(${R}, ${G}, ${B}, ${alpha})`;
}

/**
 * Function to determine if a color does not have enough contrast against a
 * light background using the YIQ color space.
 * [Source:] https://24ways.org/2010/calculating-color-contrast
 * @param rgb - An object with RGB values of a color
 */
export const isLowContrast = ({ r, g, b }: RGB): boolean => {
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128;
};

/**
 * Converts a CSS variable representing a primitive color to its raw color value.
 *
 * This function takes a CSS variable string, unwraps it to get the primitive name,
 * and then looks up the corresponding raw color value from the `primitiveMapping`.
 *
 * @param _primitive - The CSS variable string representing the primitive color.
 * @returns The raw color value as a string if found, otherwise `null`.
 *
 * @example
 * const rawColor = primitiveCSSVarToRawColor('var(--primitives-color-basic-black)');
 * // rawColor will be '#000000'
 */
export function primitiveCSSVarToRawColor(_primitive: string): string | null {
  let primitive = _primitive;
  if (primitive.startsWith('var')) {
    // unwrap
    primitive = primitive.replace('var(--', '').slice(0, -1);
  }
  for (const namespace of keys(rawPrimitiveContract)) {
    for (const variant of keys(rawPrimitiveContract[namespace])) {
      if (rawPrimitiveContract[namespace][variant] === primitive) {
        return primitiveMapping[namespace][variant];
      }
    }
  }
  return null;
}

/**
 * Maps a design token to its raw color values for both light and dark themes.
 *
 * This function retrieves the primitive CSS variables for a given design token
 * and converts them to their raw color values using `primitiveCSSVarToRawColor`.
 *
 * @param token - The design token key from `rawColorContract`.
 * @returns An object containing the raw color values for light and dark themes,
 *          or `null` if the mapping cannot be found.
 *
 * @example
 * const colorMapping = designTokenToRawMapping('tokenColorGray1600');
 * // colorMapping will be \{ light: '#17181C', dark: '#FCFCFD' \}
 */
export function designTokenToRawMapping(
  token: keyof typeof rawColorContract,
): { light: string; dark: string } | null {
  const lightPrimitive = lightMapping[token];
  const darkPrimitive = darkMapping[token];

  const lightValue = primitiveCSSVarToRawColor(lightPrimitive);
  const darkValue = primitiveCSSVarToRawColor(darkPrimitive);

  if (lightValue === null || darkValue === null) return null;

  return {
    light: lightValue,
    dark: darkValue,
  };
}
