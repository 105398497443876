import { ValidationError } from './0x.types';

export enum ErrorContextType {
  DEFAULT = 'error',
  VALIDATION_ERROR = 'validationError',
}

export interface ErrorContext {
  type: ErrorContextType;
  code: number;
  reason?: string;
}

export interface ValidationErrorContext extends ErrorContext {
  validationErrors: ValidationError[];
}

export class HttpError<ContextType> extends Error {
  public readonly name: string = 'HttpError';
  public readonly statusCode: number;

  constructor(
    public readonly status: number,
    public readonly message: string,
    public readonly context?: ContextType,
  ) {
    super(message);
    this.statusCode = status;
  }
}

export class BadRequestError<ContextType> extends HttpError<ContextType> {
  public readonly name: string = 'BadRequestError';

  constructor(
    public readonly message: string = 'Bad Request',
    public readonly context?: ContextType,
  ) {
    super(400, message, context);
  }
}

export class NotFoundError<ContextType> extends HttpError<ContextType> {
  public readonly name: string = 'NotFoundError';

  constructor(
    public readonly message: string = 'Not Found',
    public readonly context?: ContextType,
  ) {
    super(404, message, context);
  }
}

export class InternalError<ContextType> extends HttpError<ContextType> {
  public readonly name: string = 'InternalError';

  constructor(
    public readonly message: string = 'Internal Error',
    public readonly context?: ContextType,
  ) {
    super(500, message, context);
  }
}

export class BadGatewayError<ContextType> extends HttpError<ContextType> {
  public readonly name: string = 'BadGatewayError';

  constructor(
    public readonly message: string = 'Bad Gateway',
    public readonly context?: ContextType,
  ) {
    super(502, message, context);
  }
}

export class ServiceUnavailableError<ContextType> extends HttpError<ContextType> {
  public readonly name: string = 'ServiceUnavailableError';

  constructor(
    public readonly message: string = 'Service Unavailable',
    public readonly context?: ContextType,
  ) {
    super(503, message, context);
  }
}
