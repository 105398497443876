import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { animated, useSpring } from '@react-spring/web';
import {
  KeyboardEventHandler,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CheckmarkIcon, ChevronLeftIcon, LaptopIcon, NightThemeIcon, SunIcon } from '@/ui/Icons';

import { IDENTIFY_TYPES, identifyUserProperties, logEvent } from '@/utils/amplitude';
import { designTokenToRawMapping } from '@/utils/colors';

import {
  buttonClass,
  checkmarkClass,
  containerClass,
  goBackClass,
  iconClass,
  itemClass,
} from './index.css';

export type ThemeMode = 'light' | 'dark' | 'system';
export const THEME_STORAGE_KEY = 'matcha-theme';

function setToggleMode(mode: ThemeMode) {
  localStorage.setItem(THEME_STORAGE_KEY, mode);
  document.body.dataset['theme'] = mode;
}

interface DarkmodeTogglePageProps {
  onGoBack: (e: React.MouseEvent | React.KeyboardEvent) => void;
}

export const DarkmodeContext = createContext<{
  theme: ThemeMode;
  setTheme: (t: ThemeMode) => void;
}>({
  theme: 'system',
  setTheme: () => {},
});

const rawHomepageBg = designTokenToRawMapping('specialBackgroundHomepage');

function updateMetaThemeColor(mode: Exclude<ThemeMode, 'system'>) {
  if (!rawHomepageBg) {
    console.warn('WARNING: missing homepage color mapping for theme color');
    return;
  }
  let metaTag = document.querySelector('meta[name="theme-color"]');
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'theme-color');
    document.head.appendChild(metaTag);
  }
  metaTag.setAttribute('content', mode === 'dark' ? rawHomepageBg.dark : rawHomepageBg.light);
}

export function DarkmodeProvider({ children }: { children: (theme: ThemeMode) => ReactNode }) {
  const [theme, setTheme] = useState<ThemeMode>('system');

  useEffect(() => {
    // listen for changes across tabs
    const listener = (event: StorageEvent) => {
      if (event.key === THEME_STORAGE_KEY) {
        setToggleMode(event.newValue as ThemeMode);
        setTheme(event.newValue as ThemeMode);
      }
    };

    window.addEventListener('storage', listener);

    // initialize
    const previousValue = localStorage.getItem(THEME_STORAGE_KEY);
    if (previousValue) {
      setTheme(previousValue as ThemeMode);
    }
  }, []);

  // analytics
  useEffect(() => {
    let listener: null | ((e: MediaQueryListEvent) => void) = null;
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    if (theme === 'system') {
      listener = (e: MediaQueryListEvent) => {
        if (e.matches) {
          // we are in system mode and switched into dark mode
          identifyUserProperties({
            type: IDENTIFY_TYPES.SET,
            key: 'theme_toggle_position',
            value: 'dark',
          });
          updateMetaThemeColor('dark');
        } else {
          // we are in system mode and switched into light mode
          identifyUserProperties({
            type: IDENTIFY_TYPES.SET,
            key: 'theme_toggle_position',
            value: 'light',
          });
          updateMetaThemeColor('light');
        }
      };
      isDarkMode.addEventListener('change', listener);
      if (isDarkMode.matches) {
        identifyUserProperties({
          type: IDENTIFY_TYPES.SET,
          key: 'theme_toggle_position',
          value: 'dark',
        });
        updateMetaThemeColor('dark');
      } else {
        identifyUserProperties({
          type: IDENTIFY_TYPES.SET,
          key: 'theme_toggle_position',
          value: 'light',
        });
        updateMetaThemeColor('light');
      }
    } else {
      // user explicitly set light or dark mode
      identifyUserProperties({
        type: IDENTIFY_TYPES.SET,
        key: 'theme_toggle_position',
        value: theme,
      });
      updateMetaThemeColor(theme);
    }

    return () => {
      if (listener !== null) {
        isDarkMode.removeEventListener('change', listener);
      }
    };
  }, [theme]);

  return (
    <DarkmodeContext.Provider value={{ theme, setTheme }}>
      {children(theme)}
    </DarkmodeContext.Provider>
  );
}

export function DarkmodeTogglePage({ onGoBack }: DarkmodeTogglePageProps) {
  const { theme, setTheme } = useContext(DarkmodeContext);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const toggleGroupRootRef = useRef<HTMLDivElement>(null);

  const props = useSpring({
    from: {
      transform: 'translateX(80px)',
    },
    to: {
      transform: 'translateX(0)',
    },
  });

  useEffect(() => {
    // on mount, set focus to the back button
    buttonRef.current?.focus();

    // containerRef.current?.focus();
  }, []);

  const onChangeHandler = (newVal: ThemeMode) => {
    if (!newVal) return;
    setToggleMode(newVal);
    setTheme(newVal);
    logEvent({ name: 'click_theme_toggle', properties: { position: newVal } });
  };

  const buttonKeyDownHandler: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (e.key == 'ArrowDown') {
      toggleGroupRootRef.current?.focus();
    }
  };

  const groupKeyDownHandler: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (
      e.key === 'ArrowUp' &&
      (e.currentTarget.firstChild as HTMLButtonElement).getAttribute('tabindex') === '0'
    ) {
      buttonRef.current?.focus();
    }
  };

  return (
    <div>
      <div className={goBackClass}>
        <button
          ref={buttonRef}
          className={buttonClass}
          onKeyDown={buttonKeyDownHandler}
          aria-label="Go back"
          onClick={onGoBack}
        >
          <ChevronLeftIcon />
        </button>
        Appearance
      </div>
      <animated.div style={props}>
        <ToggleGroup.Root
          ref={toggleGroupRootRef}
          className={containerClass}
          type="single"
          loop={false}
          value={theme}
          onKeyDown={groupKeyDownHandler}
          onValueChange={onChangeHandler}
        >
          <ToggleGroup.Item className={itemClass} value="light">
            <SunIcon className={iconClass} />
            Light
            {theme === 'light' && <CheckmarkIcon className={checkmarkClass} />}
          </ToggleGroup.Item>
          <ToggleGroup.Item className={itemClass} value="dark">
            <NightThemeIcon className={iconClass} />
            Dark
            {theme === 'dark' && <CheckmarkIcon className={checkmarkClass} />}
          </ToggleGroup.Item>
          <ToggleGroup.Item className={itemClass} value="system">
            <LaptopIcon className={iconClass} />
            System
            {theme === 'system' && <CheckmarkIcon className={checkmarkClass} />}
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </animated.div>
    </div>
  );
}
