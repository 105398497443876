export const routes = {
  // app routes
  INDEX: '/',
  PRIVACY: '/privacy',
  TRADE_HISTORY: '/history/[[...tab]]',
  TX: '/tx/[chainId]/[txHash]',
  TOKEN_PROFILE: '/tokens/[chainId]/[...contractAddresses]',
  CROSS_CHAIN_SWAPS: '/cross-chain-swaps',
  SWAP_TOKENS: '/swap-tokens',
  LIMIT_ORDERS: '/limit-orders',
  ABOUT_MATCHA: '/about-matcha-dex-aggregator',
  LIQUIDITY_SOURCES: '/liquidity',
  MATCHA_AUTO: '/matcha-auto',
  NEWSLETTER_SIGNUP: '/newsletter-signup',
  STORE_SIGNUP: '/store',
  TERMS: '/terms',
  TRADE: '/trade',
  ERROR_404: '/404',

  // external resources
  BLOG: 'https://blog.matcha.xyz',
  HELP: 'https://help.matcha.xyz',
  HELP_APPROVE_TOKEN:
    'https://help.matcha.xyz/en/articles/4285134-why-do-i-need-to-approve-my-tokens-before-i-can-trade-them',
  HELP_FEE_ON_TRANSFER: 'https://help.matcha.xyz/en/articles/7239773-what-are-buy-sell-tax-tokens',
  HELP_FAQ: 'https://help.matcha.xyz/en/collections/2207940-faq',
  HELP_GETTING_STARTED: 'https://help.matcha.xyz/en/collections/2290272-getting-started',
  BRAND_ASSETS:
    'https://drive.google.com/drive/folders/16z4lhn1h9pm7n12b0hTMwzCknjtZKzaf?usp=sharing',
  HELP_WALLETS: 'https://help.matcha.xyz/en/collections/2438298-browsers-and-wallets',
  HELP_WALLETS_LEARN_MORE: 'https://help.matcha.xyz/en/articles/3952635-what-is-an-ethereum-wallet',
  HELP_WHAT_IS_PRICE_IMPACT: 'https://help.matcha.xyz/en/articles/7251476-what-is-price-impact',
  MATCHA_AUTO_BLOG_POST: 'https://matcha.xyz/matcha-auto',
  REQUEST_FEATURE: 'https://matcha.canny.io/request-features',
  ZEROEX_HOME: 'https://www.0x.org/',
  ZEROEX_EXPLORER: 'https://explorer.0xprotocol.org/liquiditySources',
  COINBASE_SMART_WALLET_URL: 'https://wallet.coinbase.com/',
  DEFINED_FI: 'https://defined.fi/',
  // socials
  TELEGRAM: 'https://t.me/matchaxyz',
  TWITTER: 'https://twitter.com/matchaxyz',
  WARPCAST: 'https://warpcast.com/matchaxyz',
  DISCORD: 'https://discord.com/invite/matchaxyz',
  spindl: {
    EVENTS_SERVER: 'https://spindl.link/events/server',
    SWAP_EVENT: '/api/refer/swap',
  },
  api: {
    EMAIL: '/api/email',
    GOOGLE_TOKEN: '/api/google-token',
    TIME_SERIES: '/api/time-series',
    USD_PRICES: '/api/price/usd',
    TOKEN_SUPPORTS_PERMIT: '/api/tokens/support/permit',
    TOKEN_FEE_CONFIG: '/api/tokens/fees',
    TRENDING_TOKENS: '/api/tokens/trending',
    TOKENS_CONTENT: '/api/tokens/content',
    TOKENS_BALANCE: '/api/tokens/balance',
    TOKENS_INFO: '/api/tokens/info',
    TRADE_HISTORY: '/api/trade-history',
    TRADE_HISTORY_ROUTE: '/api/trade-history/route',
    LIMIT_ORDER: '/api/orderbook/order',
    LIMIT_ORDERS: '/api/orderbook/orders',
    LOG_TRADE: '/api/trade/log',
    LOG_SCROLL_PROGRESS: '/api/scroll/log',
    REFERRAL_INFO: '/api/refer/info',
    SWAP_PRICE: '/api/swap/price',
    SWAP_QUOTE: '/api/swap/quote',
    SOURCES: '/api/sources',
    SOURCES_ALL: '/api/sources/all',
    SEARCH_TOKENS: '/api/tokens/search',
    GASLESS_PRICE: '/api/gasless/price',
    GASLESS_QUOTE: '/api/gasless/quote',
    GASLESS_SUBMIT: '/api/gasless/submit',
    GASLESS_STATUS: '/api/gasless/status',
    CROSS_CHAIN_QUOTE: '/api/cross-chain/quote',
    GAS_PRICE: '/api/gas',
    SOLANA_SWAP_QUOTE: '/api/swap/quote/solana',
  },
} as const;
const marketingPages: string[] = [
  routes.INDEX,
  routes.CROSS_CHAIN_SWAPS,
  routes.SWAP_TOKENS,
  routes.LIMIT_ORDERS,
  routes.LIQUIDITY_SOURCES,
  routes.ABOUT_MATCHA,
  routes.MATCHA_AUTO,
  routes.NEWSLETTER_SIGNUP,
  routes.STORE_SIGNUP,
];

export const isMarketingPage = (path: string) => marketingPages.includes(path);
