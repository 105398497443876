import * as ToastPrimitive from '@radix-ui/react-toast';
import * as Sentry from '@sentry/nextjs';
import { PrivyProvider } from '@privy-io/react-auth';
import { toSolanaWalletConnectors } from '@privy-io/react-auth/solana';
import { WagmiProvider } from '@privy-io/wagmi';
import { SSRProvider as ReactAriaSSRProvider } from '@react-aria/ssr';
import { QueryClientProvider } from '@tanstack/react-query';
import { SpeedInsights } from '@vercel/speed-insights/next';
import Decimal from 'decimal.js-light';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { ErrorBoundary } from '@/components/App/ErrorBoundary';
import { FarcasterFrameProvider } from '@/components/App/FarcasterFrameProvider';
import { containerStyles } from '@/components/App/index.css';
import { DarkmodeProvider } from '@/components/Header/DarkmodeToggle';
import { IntercomProvider } from '@/components/IntercomProvider';
import AppUpdater from '@/components/Updater/AppUpdater';
import TransactionUpdater from '@/components/Updater/TransactionUpdater';

import { SOLANA_PHASE1_COOKIE_KEY, useFeature } from '@/hooks/useFeature';
import { useHideShowIntercom } from '@/hooks/useHideShowIntercom';
import { useNodeProviderToken } from '@/hooks/useNodeProviderToken';

import { referrersStoreSelectors, useReferrerStore } from '@/store/referrals/';

import '@/styles/globals.css';
import { primitiveMapping } from '@/styles/primitives.css';
import { breakpoints } from '@/styles/util';

import { Fonts } from '@/ui/Fonts';

import { initializeAmplitudeAnalytics } from '@/utils/amplitude';
import { walletList } from '@/utils/privy';
import { PRIVY_ID, config as privyConfig } from '@/utils/privy';
import { initRedditPixel } from '@/utils/reddit';
import { isMarketingPage } from '@/utils/routes';
import { queryClient, config as wagmiConfig } from '@/utils/wagmi';

import { DEFAULT_SEO_CONFIG } from '../../next-seo.config';
// patch ref: https://github.com/facebook/react/issues/11538#issuecomment-417504600
import '../../node-patch';
import { SankeyProvider } from '../components/SankeyDiagram/SankeyProvider';
import '../global.d.ts';

const solanaConnectors = toSolanaWalletConnectors({
  // By default, shouldAutoConnect is enabled
  shouldAutoConnect: true,
});

if (process.env.NODE_ENV !== 'test' && typeof window !== 'undefined') {
  initializeAmplitudeAnalytics();
  initRedditPixel();
}

function MatchaApp({ Component, pageProps }: AppProps) {
  useNodeProviderToken();
  const router = useRouter();
  const [canTrade, setCanTrade] = useState(true);
  const isMobile = useMedia(breakpoints.mobile, false);
  const wagmiConfigChains = wagmiConfig.chains.reduce<Record<string, number>>((acc, curr) => {
    acc[curr.name] = curr.id;
    return acc;
  }, {});

  useHideShowIntercom(isMobile);

  Sentry.setContext(
    `Wagmi Configured Chains ${typeof window === 'undefined' ? 'server' : 'client'}`,
    wagmiConfigChains,
  );

  const hasContainerHomeStyles = isMarketingPage(router.pathname);

  // referrals
  const { ref } = router.query;
  const { setReferrerId, referrerId, isExpired } = useReferrerStore(referrersStoreSelectors);
  const isSolanaPhase1 = useFeature(SOLANA_PHASE1_COOKIE_KEY);
  useEffect(() => {
    if (ref && (referrerId !== ref || isExpired())) {
      setReferrerId(ref.toString());
    }
  }, [ref, referrerId, setReferrerId, isExpired]);

  useEffect(() => {
    // Ensure decimal-js settings are correct on mount
    Decimal.set({ precision: 80, toExpPos: 1000, toExpNeg: -1000, rounding: Decimal.ROUND_DOWN });
  }, []);

  const [preferredColorScheme] = useState<'light' | 'dark'>(() => {
    return typeof window !== 'undefined' &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  });

  return (
    <>
      <DefaultSeo {...DEFAULT_SEO_CONFIG} />
      <Fonts />
      <ErrorBoundary>
        <DarkmodeProvider>
          {(theme) => (
            <SankeyProvider>
              <ToastPrimitive.Provider>
                <ReactAriaSSRProvider>
                  <PrivyProvider
                    appId={PRIVY_ID}
                    config={{
                      ...privyConfig,
                      externalWallets: true ? { solana: { connectors: solanaConnectors } } : {},
                      appearance: {
                        ...privyConfig.appearance,
                        walletChainType: isSolanaPhase1 ? 'ethereum-and-solana' : 'ethereum-only',
                        walletList: isMobile ? walletList : undefined,
                        theme:
                          preferredColorScheme === 'dark' || theme === 'dark'
                            ? (primitiveMapping.gray[1300] as `#${string}`)
                            : 'light',
                      },
                    }}
                  >
                    <QueryClientProvider client={queryClient}>
                      <WagmiProvider config={wagmiConfig}>
                        <FarcasterFrameProvider config={wagmiConfig}>
                          <IntercomProvider>
                            <div
                              className={
                                hasContainerHomeStyles
                                  ? containerStyles.home
                                  : containerStyles.default
                              }
                              id="app"
                            >
                              <Component {...pageProps} canTrade={canTrade} />
                              <AppUpdater onSetCanTrade={(value: boolean) => setCanTrade(value)} />
                            </div>
                          </IntercomProvider>
                          <TransactionUpdater />
                        </FarcasterFrameProvider>
                      </WagmiProvider>
                    </QueryClientProvider>
                  </PrivyProvider>
                </ReactAriaSSRProvider>
              </ToastPrimitive.Provider>
            </SankeyProvider>
          )}
        </DarkmodeProvider>
      </ErrorBoundary>
      <SpeedInsights sampleRate={0.15} />
    </>
  );
}

export default MatchaApp;
