import { ConnectedSolanaWallet } from '@privy-io/react-auth';
import { ConnectedWallet } from '@privy-io/react-auth';

import { solana, SUPPORTED_CHAINS_BY_CHAIN_ID } from '../constants/chain';

export function getConnectedChain(wallet: ConnectedWallet | ConnectedSolanaWallet | undefined) {
  if (!wallet) return undefined;
  if ('chainId' in wallet) {
    const split = wallet.chainId.split(':');
    return SUPPORTED_CHAINS_BY_CHAIN_ID[Number(split[split.length - 1])];
  } else if (wallet.type === 'solana') {
    return solana;
  }
  return undefined;
}
