// utilities to evaluate the current application environment
export const isDev =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' ||
  typeof process.env.NEXT_PUBLIC_VERCEL_ENV === 'undefined';
export const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
export const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
export const isTest = process.env.NODE_ENV === 'test';

export const BASE_URL_PRODUCTION = 'https://matcha.xyz';

// returns the baseURL of the application in the current environment
export const getBaseURL = (): string => {
  let hostname = '',
    port = '',
    protocol = '';

  if (isTest) {
    // In test environment, use production URL
    hostname = 'matcha.xyz';
    protocol = 'https';
  } else if (isProd) {
    hostname = 'matcha.xyz';
    protocol = 'https';
  } else if (isPreview) {
    hostname = process.env.NEXT_PUBLIC_VERCEL_URL || '';
    protocol = 'https';
  } else {
    hostname = 'localhost';
    protocol = 'http';
    port = ':3000';
  }

  return `${protocol}://${hostname}${port}`;
};
