import { ChainAddress, EthereumAddress, SolanaAddress } from '@/utils/models';

import { STABLES_PER_CHAIN } from '../utils/fee-data';
import { CHAIN_IDS } from './chain';

// Note:
// All the contract addresses should be lowercase to avoid having to lowercase them everywhere in the codebase

interface ChainTokens {
  [key: string]: string;
}

interface TokenAddresses {
  [key: string]: ChainTokens;
}

export const ZEROEX_API_AFFILIATE_ADDRESS = '0x86003b044f70dac0abc80ac8957305b6370893ed';

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const NULL_STAKING_POOL =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

export const BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD' as EthereumAddress;
export const BURN_ADDRESS_SOLANA = 'dead111111111111111111111111111111111111111' as SolanaAddress;

export const ETH_FAKE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee' as const;

interface ContractByChainId {
  [chainId: number]: ChainAddress;
}

// WETH
const WETH_ADDRESS_MAINNET = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
const WETH_ADDRESS_SCROLL = '0x5300000000000000000000000000000000000004';
const WETH_ADDRESS_ARBITRUM = '0x82af49447d8a07e3bd95bd0d56f35241523fbab1';
const WETH_ADDRESS_MATIC = '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619';
const WETH_ADDRESS_OPTIMISM = '0x4200000000000000000000000000000000000006';
const WETH_ADDRESS_BASE = '0x4200000000000000000000000000000000000006';
const WETH_ADDRESS_BLAST = '0x4300000000000000000000000000000000000004';
const WETH_ADDRESS_LINEA = '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f';
const WETH_ADDRESS_MANTLE = '0xdeaddeaddeaddeaddeaddeaddeaddeaddead1111';
const WETH_ADDRESS_MODE = '0x4200000000000000000000000000000000000006';
const WETH_ADDRESS_UNICHAIN = '0x4200000000000000000000000000000000000006';

// Wrapped tokens (not WETH)
const WAVAX_ADDRESS_AVALANCHE = '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7';
const BNB_ADDRESS_BSC = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';
const WMATIC_ADDRESS_MATIC = '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270';
const WMNT_ADDRESS_MANTLE = '0x78c1b0c915c4faa5fffa6cabf0219da63d7f4cb8';
export const WSOL_ADDRESS_SOLANA = 'So11111111111111111111111111111111111111112' as SolanaAddress;

// Other tokens
const MATIC_ADDRESS_MATIC = '0x0000000000000000000000000000000000001010';
export const MATIC_ADDRESS_MAINNET = '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0';
export const MNT_ADDRESS_MANTLE = '0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000';
export const MODE_ADDRESS_MODE = '0xdfc7c877a950e49d2610114102175a06c2e3167a';
export const AERODROME_ADDRESS_BASE = '0x940181a94a35a4569e4529a3cdfb74e38fd98631';
export const BLAST_ADDRESS_BLAST = '0xb1a5700fa2358173fe465e6ea4ff52e36e88e2ad';

export const NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.ARBITRUM]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.AVALANCHE]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.BASE]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.MATIC]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.OPTIMISM]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.SCROLL]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.BSC]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.BLAST]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.LINEA]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.MANTLE]: MNT_ADDRESS_MANTLE,
  [CHAIN_IDS.MODE]: ETH_FAKE_ADDRESS,
  [CHAIN_IDS.SOLANA]: WSOL_ADDRESS_SOLANA,
  [CHAIN_IDS.UNICHAIN]: ETH_FAKE_ADDRESS,
} as const;

export const WETH_ADDRESSES: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: WETH_ADDRESS_MAINNET,
  [CHAIN_IDS.ARBITRUM]: WETH_ADDRESS_ARBITRUM,
  [CHAIN_IDS.BASE]: WETH_ADDRESS_BASE,
  [CHAIN_IDS.MATIC]: WETH_ADDRESS_MATIC,
  [CHAIN_IDS.OPTIMISM]: WETH_ADDRESS_OPTIMISM,
  [CHAIN_IDS.SCROLL]: WETH_ADDRESS_SCROLL,
  [CHAIN_IDS.BLAST]: WETH_ADDRESS_BLAST,
  [CHAIN_IDS.LINEA]: WETH_ADDRESS_LINEA,
  [CHAIN_IDS.MANTLE]: WETH_ADDRESS_MANTLE,
  [CHAIN_IDS.MODE]: WETH_ADDRESS_MODE,
  [CHAIN_IDS.UNICHAIN]: WETH_ADDRESS_UNICHAIN,
} as const;

export const WRAPPED_NATIVE_ADDRESSES: ContractByChainId = {
  ...WETH_ADDRESSES,
  [CHAIN_IDS.AVALANCHE]: WAVAX_ADDRESS_AVALANCHE,
  [CHAIN_IDS.BSC]: BNB_ADDRESS_BSC,
  [CHAIN_IDS.MATIC]: WMATIC_ADDRESS_MATIC, // WMATIC replaces MATIC WETH
  [CHAIN_IDS.MANTLE]: WMNT_ADDRESS_MANTLE,
  [CHAIN_IDS.SOLANA]: WSOL_ADDRESS_SOLANA,
} as const;

interface NativeTokenAddressForBlockExplorerByChainId {
  [chainId: number]: ChainAddress | undefined;
}
export const NATIVE_TOKEN_ADDRESS_FOR_BLOCK_EXPLORER_BY_CHAIN_ID: NativeTokenAddressForBlockExplorerByChainId =
  {
    [CHAIN_IDS.MATIC]: MATIC_ADDRESS_MATIC,
    [CHAIN_IDS.MANTLE]: MNT_ADDRESS_MANTLE,
  } as const;

// USDC
const USDC_ADDRESS_MAINNET = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
const USDC_ADDRESS_ARBITRUM = '0xaf88d065e77c8cc2239327c5edb3a432268e5831';
const USDC_ADDRESS_MATIC = '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359';
const USDC_ADDRESS_OPTIMISM = '0x0b2c639c533813f4aa9d7837caf62653d097ff85';
const USDC_ADDRESS_BASE = '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913';
const USDC_ADDRESS_BSC = '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d';
const USDC_ADDRESS_AVALANCHE = '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664';
const USDC_ADDRESS_SCROLL = '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4';
const USDC_ADDRESS_LINEA = '0x176211869ca2b568f2a7d4ee941e073a821ee1ff';
const USDC_ADDRESS_MANTLE = '0x09bc4e0d864854c6afb6eb9a9cdf58ac190d0df9';
const USDC_ADDRESS_MODE = '0xd988097fb8612cc24eeC14542bC03424c656005f';
const USDC_ADDRESS_UNICHAIN = '0x078d782b760474a361dda0af3839290b0ef57ad6';
const USDC_ADDRESS_SOLANA = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v' as SolanaAddress;

// USDT
const USDT_ADDRESS_MAINNET = '0xdac17f958d2ee523a2206206994597c13d831ec7';
const USDT_ADDRESS_ARBITRUM = '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9';
const USDT_ADDRESS_MATIC = '0xc2132d05d31c914a87c6611c10748aeb04b58e8f';
const USDT_ADDRESS_OPTIMISM = '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58';
const USDT_ADDRESS_BSC = '0x55d398326f99059ff775485246999027b3197955';
const USDT_ADDRESS_AVALANCHE = '0xc7198437980c041c805a1edcba50c1ce5db95118';
const USDT_ADDRESS_SCROLL = '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df';
const USDT_ADDRESS_LINEA = '0xa219439258ca9da29e9cc4ce5596924745e12b93';
const USDT_ADDRESS_MANTLE = '0x201eba5cc46d216ce6dc03f6a759e8e766e956ae';
const USDT_ADDRESS_MODE = '0xf0F161fDA2712DB8b566946122a5af183995e2eD';
const USDT_ADDRESS_UNICHAIN = '0x588ce4f028d8e7b53b687865d6a67b3a54c75518';
const USDT_ADDRESS_SOLANA = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB' as SolanaAddress;

// DAI
const DAI_ADDRESS_MAINNET = '0x6b175474e89094c44da98b954eedeac495271d0f';
const DAI_ADDRESS_ARBITRUM = '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1';
const DAI_ADDRESS_MATIC = '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063';
const DAI_ADDRESS_OPTIMISM = '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1';
const DAI_ADDRESS_BASE = '0x50c5725949a6f0c72e6c4a641f24049a917db0cb';
const DAI_ADDRESS_BSC = '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3';
const DAI_ADDRESS_AVALANCHE = '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70';
const DAI_ADDRESS_SCROLL = '0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97';
const DAI_ADDRESS_LINEA = '0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5';
const DAI_ADDRESS_UNICHAIN = '0x20CAb320A855b39F724131C69424240519573f81';

// WBTC
const WBTC_ADDRESS_MAINNET = '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599';
const WBTC_ADDRESS_ARBITRUM = '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f';
const WBTC_ADDRESS_MATIC = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6';
const WBTC_ADDRESS_OPTIMISM = '0x68f180fcCe6836688e9084f035309E29Bf0A2095';
const WBTC_ADDRESS_AVALANCHE = '0x50b7545627a5162F82A992c33b87aDc75187B218';
const WBTC_ADDRESS_SCROLL = '0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1';
const WBTC_ADDRESS_LINEA = '0x3aab2285ddcddad8edf438c1bab47e1a9d05a9b4';
const WBTC_ADDRESS_MANTLE = '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2';
const WBTC_ADDRESS_BLAST = '0xf7bc58b8d8f97adc129cfc4c9f45ce3c0e1d2692';
const WBTC_ADDRESS_MODE = '0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf';
const WBTC_ADDRESS_UNICHAIN = '0x927b51f251480a681271180da4de28d44ec4afb8';
const WBTC_ADDRESS_SOLANA = '3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh' as SolanaAddress;

// Additional Token Suggestions
const USDbC_ADDRESS_BASE = '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca';
const USDB_ADDRESS_BLAST = '0x4300000000000000000000000000000000000003';

export const STABLES_PER_CHAIN_BY_ADDRESS = Object.entries(
  STABLES_PER_CHAIN,
).reduce<TokenAddresses>((chainAddressMapping, [chainId, tokens]) => {
  chainAddressMapping[chainId] = Object.entries(tokens).reduce<ChainTokens>(
    (tokenAddressMapping, [tokenSymbol, tokenAddress]) => {
      tokenAddressMapping[tokenAddress.toLowerCase()] = tokenSymbol;
      return tokenAddressMapping;
    },
    {},
  );
  return chainAddressMapping;
}, {});

export const USDC_ADDRESSES: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: USDC_ADDRESS_MAINNET,
  [CHAIN_IDS.ARBITRUM]: USDC_ADDRESS_ARBITRUM,
  [CHAIN_IDS.MATIC]: USDC_ADDRESS_MATIC,
  [CHAIN_IDS.OPTIMISM]: USDC_ADDRESS_OPTIMISM,
  [CHAIN_IDS.BSC]: USDC_ADDRESS_BSC,
  [CHAIN_IDS.AVALANCHE]: USDC_ADDRESS_AVALANCHE,
  [CHAIN_IDS.BASE]: USDC_ADDRESS_BASE,
  [CHAIN_IDS.SCROLL]: USDC_ADDRESS_SCROLL,
  [CHAIN_IDS.LINEA]: USDC_ADDRESS_LINEA,
  [CHAIN_IDS.MANTLE]: USDC_ADDRESS_MANTLE,
  [CHAIN_IDS.MODE]: USDC_ADDRESS_MODE,
  [CHAIN_IDS.UNICHAIN]: USDC_ADDRESS_UNICHAIN,
  [CHAIN_IDS.SOLANA]: USDC_ADDRESS_SOLANA,
} as const;

export const NATIVE_USDC_PER_CHAIN_ID: ContractByChainId = {
  [CHAIN_IDS.AVALANCHE]: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
  [CHAIN_IDS.ARBITRUM]: USDC_ADDRESS_ARBITRUM,
  [CHAIN_IDS.BASE]: USDC_ADDRESS_BASE,
  [CHAIN_IDS.MAINNET]: USDC_ADDRESS_MAINNET,
  [CHAIN_IDS.MATIC]: USDC_ADDRESS_MATIC,
  [CHAIN_IDS.OPTIMISM]: USDC_ADDRESS_OPTIMISM,
  [CHAIN_IDS.UNICHAIN]: USDC_ADDRESS_UNICHAIN,
  [CHAIN_IDS.SOLANA]: USDC_ADDRESS_SOLANA,
};

export const USDT_ADDRESSES: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: USDT_ADDRESS_MAINNET,
  [CHAIN_IDS.MATIC]: USDT_ADDRESS_MATIC,
  [CHAIN_IDS.BSC]: USDT_ADDRESS_BSC,
  [CHAIN_IDS.AVALANCHE]: USDT_ADDRESS_AVALANCHE,
  [CHAIN_IDS.OPTIMISM]: USDT_ADDRESS_OPTIMISM,
  [CHAIN_IDS.ARBITRUM]: USDT_ADDRESS_ARBITRUM,
  [CHAIN_IDS.SCROLL]: USDT_ADDRESS_SCROLL,
  [CHAIN_IDS.LINEA]: USDT_ADDRESS_LINEA,
  [CHAIN_IDS.MANTLE]: USDT_ADDRESS_MANTLE,
  [CHAIN_IDS.MODE]: USDT_ADDRESS_MODE,
  [CHAIN_IDS.UNICHAIN]: USDT_ADDRESS_UNICHAIN,
  [CHAIN_IDS.SOLANA]: USDT_ADDRESS_SOLANA,
} as const;

// DAI
export const DAI_ADDRESSES: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: DAI_ADDRESS_MAINNET,
  [CHAIN_IDS.ARBITRUM]: DAI_ADDRESS_ARBITRUM,
  [CHAIN_IDS.MATIC]: DAI_ADDRESS_MATIC,
  [CHAIN_IDS.OPTIMISM]: DAI_ADDRESS_OPTIMISM,
  [CHAIN_IDS.BASE]: DAI_ADDRESS_BASE,
  [CHAIN_IDS.BSC]: DAI_ADDRESS_BSC,
  [CHAIN_IDS.AVALANCHE]: DAI_ADDRESS_AVALANCHE,
  [CHAIN_IDS.SCROLL]: DAI_ADDRESS_SCROLL,
  [CHAIN_IDS.LINEA]: DAI_ADDRESS_LINEA,
  [CHAIN_IDS.UNICHAIN]: DAI_ADDRESS_UNICHAIN,
} as const;

export const WBTC_ADDRESSES: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: WBTC_ADDRESS_MAINNET,
  [CHAIN_IDS.ARBITRUM]: WBTC_ADDRESS_ARBITRUM,
  [CHAIN_IDS.MATIC]: WBTC_ADDRESS_MATIC,
  [CHAIN_IDS.OPTIMISM]: WBTC_ADDRESS_OPTIMISM,
  [CHAIN_IDS.AVALANCHE]: WBTC_ADDRESS_AVALANCHE,
  [CHAIN_IDS.SCROLL]: WBTC_ADDRESS_SCROLL,
  [CHAIN_IDS.BLAST]: WBTC_ADDRESS_BLAST,
  [CHAIN_IDS.LINEA]: WBTC_ADDRESS_LINEA,
  [CHAIN_IDS.MANTLE]: WBTC_ADDRESS_MANTLE,
  [CHAIN_IDS.MODE]: WBTC_ADDRESS_MODE,
  [CHAIN_IDS.UNICHAIN]: WBTC_ADDRESS_UNICHAIN,
  [CHAIN_IDS.SOLANA]: WBTC_ADDRESS_SOLANA,
} as const;

export const ADDITIONAL_SUGGESTED_ADDRESSES: ContractByChainId = {
  [CHAIN_IDS.BASE]: USDbC_ADDRESS_BASE,
  [CHAIN_IDS.BLAST]: USDB_ADDRESS_BLAST,
};

// used in liquidity score calculation
export const REFERENCE_TOKEN_ADDRESS_BY_CHAIN_ID: ContractByChainId = {
  [CHAIN_IDS.MAINNET]: USDC_ADDRESS_MAINNET,
  [CHAIN_IDS.ARBITRUM]: USDC_ADDRESS_ARBITRUM,
  [CHAIN_IDS.MATIC]: USDC_ADDRESS_MATIC,
  [CHAIN_IDS.OPTIMISM]: USDC_ADDRESS_OPTIMISM,
  [CHAIN_IDS.BSC]: USDC_ADDRESS_BSC,
  [CHAIN_IDS.AVALANCHE]: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e', // more liquid USDC
  [CHAIN_IDS.BASE]: USDC_ADDRESS_BASE,
  [CHAIN_IDS.SCROLL]: USDC_ADDRESS_SCROLL,
  [CHAIN_IDS.LINEA]: USDC_ADDRESS_LINEA,
  [CHAIN_IDS.MANTLE]: USDC_ADDRESS_MANTLE,
  [CHAIN_IDS.BLAST]: USDB_ADDRESS_BLAST,
  [CHAIN_IDS.MODE]: USDC_ADDRESS_MODE,
  [CHAIN_IDS.UNICHAIN]: USDC_ADDRESS_UNICHAIN,
  [CHAIN_IDS.SOLANA]: USDC_ADDRESS_SOLANA,
};

// Most traded tokens by volume
// Ethereum https://etherscan.io/tokens?sort=24h_volume_usd&order=desc
// Polygon https://polygonscan.com/tokens?sort=24h_volume_usd&order=desc
// Optimism https://optimistic.etherscan.io/tokens?sort=24h_volume_usd&order=desc
// Base https://basescan.org/tokens?sort=24h_volume_usd&order=desc
// Arbitrum https://arbiscan.io/tokens?sort=24h_volume_usd&order=desc
// BSC https://bscscan.com/tokens?sort=24h_volume_usd&order=desc
// Avalanche https://snowscan.xyz/tokens?sort=24h_volume_usd&order=desc
export const MAIN_USD_STABLE_ADDRESSES: ContractByChainId = {
  ...USDT_ADDRESSES,
  [CHAIN_IDS.BASE]: DAI_ADDRESS_BASE,
} as const;

export const EXCHANGE_PROXY_ADDRESS = '0xdef1c0ded9bec7f1a1670819833240f027b25eff';
