// @ts-nocheck
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { Intercom, LiveChatLoaderProvider } from 'react-live-chat-loader';

import { update } from './utils';

const INTERCOM_APP_ID = 'mbera9g8';

export const IntercomProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', update);
    return () => {
      router.events.off('routeChangeStart', update);
    };
  }, [router.events]);

  return (
    <LiveChatLoaderProvider providerKey={INTERCOM_APP_ID} provider="intercom">
      {children}
      <Intercom color="rgb(90, 181, 108)" />
    </LiveChatLoaderProvider>
  );
};
