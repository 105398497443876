import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { SUPPORTED_CHAINS } from '../../constants/chain';
import { TokenResult } from '../../utils/0x/token-registry.types';

// Note: This interface can be expanded to cache other relevant application level data (balance, etc)
interface TokenDetails {
  token: TokenResult;
}

export interface TokenAddressMap {
  [chainId: number]: {
    [tokenAddress: string]: TokenDetails;
  };
}

interface TokenStore {
  tokens: TokenAddressMap;
  addTokens: (tokenResults: TokenResult[]) => void;
}

/***
 * This function limits the amount of stored tokens per chain to 50.
 * This operation is done in place.
 * This number is arbitrarily chosen and its purpose is to counter the token store to grow to big in size.
 *
 */
function limitTokensPerChain(tokens: TokenAddressMap) {
  for (const chain in tokens) {
    const tokenKeys = Object.keys(tokens[chain]);
    // if we have more than 50 tokens, we keep the last 50 added tokens
    const numTokensToDelete = Math.max(0, tokenKeys.length - 50);
    for (let i = 0; i < numTokensToDelete; i++) {
      delete tokens[chain][tokenKeys[i]];
    }
  }
}
function customStorage() {
  const localStorage =
    typeof window !== 'undefined'
      ? window.localStorage
      : {
          getItem: () => null,
          setItem: () => null,
          removeItem: () => null,
        };
  if (typeof window === 'undefined') {
    console.warn(
      'localStorage is not available in this environment, transactions will not be persisted',
    );
  }
  return {
    getItem(key: string) {
      try {
        const item = localStorage.getItem(key);
        if (!item) return null;
        return item;
      } catch (error) {
        return null;
      }
    },
    setItem(key: string, value: string) {
      try {
        const parsed = JSON.parse(value) as { state: TokenStore; version: number };
        limitTokensPerChain(parsed.state.tokens);
        localStorage.setItem(key, JSON.stringify(parsed));
        // send storage event
      } catch (error) {
        console.error('Error setting item in local storage', error);
      }
    },
    removeItem(key: string) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error('Error removing item in local storage', error);
      }
    },
  };
}

export const useTokenStore = createWithEqualityFn<TokenStore>()(
  persist(
    (set, get) => ({
      tokens: SUPPORTED_CHAINS.reduce(
        (memo, chain) => {
          memo[chain.id] = {};
          return memo;
        },
        {} as { [chainId: number]: {} },
      ),
      addTokens: (tokenResults: TokenResult[]) => {
        try {
          const store = get();
          let newTokens = tokenResults.reduce(
            (obj, token: TokenResult) => {
              const chainId = token.chainId;
              obj[chainId] = {
                ...store.tokens[chainId],
                ...obj[chainId],
                [token.address]: {
                  token: {
                    ...(store.tokens[chainId]?.[token.address]?.token || {}),
                    ...token,
                  },
                },
              };

              return obj;
            },
            { ...store.tokens },
          );

          set({
            tokens: newTokens,
          });
        } catch (err: unknown) {
          console.debug('token store addTokens error:', err);
        }
      },
    }),
    {
      name: 'matcha-tokens',
      version: 2,
      storage: createJSONStorage(customStorage),
      migrate(state, version) {
        if (version == 1 && state) {
          const casted = state as TokenStore;
          limitTokensPerChain(casted.tokens);

          return casted;
        }

        return state as TokenStore;
      },
    },
  ),
  shallow,
);

export const tokensSelector = (state: TokenStore) => ({
  tokens: state.tokens,
  addTokens: state.addTokens,
});
