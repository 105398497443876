import { useCapabilities } from 'wagmi/experimental';

/**
 * Is a smart wallet connected?
 * Note: if the atomic batch capability is supported, it's a smart wallet.
 */
const useIsSmartWallet = ({ chainId }: { chainId: number | undefined }) => {
  const result = useCapabilities();
  if (!chainId || !result.data || !result.data[chainId]) return false;
  return !!result.data[chainId].atomicBatch?.supported;
};

export default useIsSmartWallet;
