const ENV = {
  // tracking
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string,
  REDDIT_PIXEL_ID: process.env.NEXT_PUBLIC_REDDIT_PIXEL_ID as string,
  // rpc keys
  ALCHEMY_RPC_KEY: process.env.NEXT_PUBLIC_ALCHEMY_RPC_KEY as string,
  ALCHEMY_INTEGRATION_TEST_API_KEY: process.env.ALCHEMY_INTEGRATION_TEST_API_KEY as string,
  // services
  NODE_ENV: process.env.NODE_ENV as string,
  // trade broadcaster
  TRADE_BROADCASTER_URL: process.env.NEXT_PUBLIC_TRADE_BROADCASTER_URL,
  // trade recipient address
  TRADE_RECIPIENT_ADDRESS: process.env.NEXT_PUBLIC_TRADE_SURPLUS_RECIPIENT_ADDRESS,
};

export default ENV;
