import * as Sentry from '@sentry/nextjs';
import type { ErrorInfo } from 'react';
import { type Address, InternalRpcError } from 'viem';

export class UseSwapQuoteError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UseSwapQuoteError';
  }
}

function hasMessage(obj: unknown): obj is { message: string } {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    typeof (obj as { message: unknown }).message === 'string'
  );
}

/**
 * For some errors like the InternalRpcError, we want to add the internally wrapped exception
 * to the fingerprint so that we can group errors by the underlying cause.
 * @param err - Exception
 * @returns The fingerprint for the exception, or undefined if we want to use the default fingerprint.
 */
export function prepareFingerprint(err: Error): string[] | undefined {
  const unwrappedChainedExceptions = [err];

  let currentExeption: unknown = err;
  while (currentExeption instanceof Error) {
    unwrappedChainedExceptions.push(currentExeption);
    currentExeption = currentExeption.cause;
  }

  for (const unwrappedException of unwrappedChainedExceptions) {
    if (unwrappedException instanceof InternalRpcError) {
      return [
        'InternalRpcError',
        hasMessage(unwrappedException.cause)
          ? unwrappedException.cause.message
          : unwrappedException.message || 'unknown error',
      ];
    }

    if (unwrappedException instanceof UseSwapQuoteError) {
      return ['UseSwapQuoteError', unwrappedException.message || 'unknown error'];
    }
  }
  return undefined;
}
type ErrorContexts = {
  /** Value of the key is either a string, number, ErrorInfo object, or another ErrorContexts object. */
  [key: string]: string | number | ErrorInfo | ErrorContexts | boolean | Address | undefined;
};

/**
 * Helper function to forward errors to Sentry
 *
 * @param err - Error object to track
 * @param contexts - Object with additional error context
 */
export const trackError = (err: Error, contexts?: ErrorContexts) => {
  const fingerprint = prepareFingerprint(err);
  if (!contexts) {
    // send error to Sentry
    Sentry.captureException(err, fingerprint ? { fingerprint } : undefined);
  } else {
    // send the error + additional error context to Sentry
    const { errorInfo, ...rest } = contexts;

    Sentry.captureException(err, {
      contexts: {
        matchaContext: rest,
      },
      extra: {
        errorInfo,
      },
      fingerprint,
    });
  }
};

// Transaction Module Error Screens and Alerts
/**
 * Price Impact Thresholds
 *
 * Levels are referred to in `Alerts/PriceImpact` - Depends on #'s 3 and 8
 * 1. Level 1 - (-8% to -3%)
 * 2. Level 2 - (-INF% to -8%)
 *
 * #5 is used to determine if `Alerts/ReviewTransaction` is rendered.
 *
 * example use case
 * ```
 *    if (priceImpact.percent + PRICE_IMPACT_THRESHOLDS[0] > 0):
 *      no alerts should appear
 * ```
 */
export const PRICE_IMPACT_THRESHOLDS = [3, 5, 8];

/**
 * Slippage percentage thresholds: [low, high]
 * Slippage settings below `low` or above `high` should render a warning.
 */
export const SLIPPAGE_THRESHOLDS = [0.001, 0.03];

export const SwapTransactionAlertMessages = {
  feeOnTransfer: {
    headerMessage: `Token Not Supported`,
    bodyMessage: ` charges an extra fee every time it is transferred. Matcha doesn't support this behavior at this time.`,
  },
  feesTooHigh: {
    headerMessage: 'Fees exceed 10% of the swap amount',
    bodyMessage: null,
  },
  unsupportedToken: {
    headerMessage: `Token Not Supported`,
    bodyMessage: ` is not supported at this time.`,
  },
};
export const TxnErrorMessages = {
  insufficientAllowance: 'Insufficient allowance',
  insufficientBalance: 'Insufficient balance',
  insufficientLiquidity: 'Insufficient liquidity',
  sellAmountTooSmall: 'sellAmount too small',
  catchAll: 'Catch All',
  canceledBeforeSubmission: 'Order confirmation denied',
  kError: 'Constant product K error',
};

// Tooltips
export const priceImpactTooltipContentHeader = `What's price impact?`;
export const priceImpactTooltipContentBody = `Price impact happens when the trade you're making impacts the price of the tokens you're trading. Generally, larger orders will experience a greater price impact, especially if limited liquidity is available in the market.`;
