import { captureEvent as logSentryEvent, setTag } from '@sentry/nextjs';
import { nanoid } from 'nanoid';
import { ErrorInfo } from 'react';
import { ZodError } from 'zod';

import { logEvent as logAmplitudeEvent } from '@/utils/amplitude';
import { isDev } from '@/utils/environment';
import { prepareFingerprint } from '@/utils/errors';

import { ApiError, ApiSellAmountTooSmallError } from './api';
import { ClientError } from './client';
import { JupiterApiError } from './jupiter';

type BaseErrorProperties = {
  /** Unique error ID */
  error_id: string;
  /** The error message */
  message: string;
  /** The type of error */
  type_of_error: string;
};

type ApiErrorProperties = BaseErrorProperties & {
  /** Request ID */
  zid?: string;
  /** Additional context */
  [key: string]: unknown;
};

type ErrorProperties = ApiErrorProperties | BaseErrorProperties;

/**
 * This function is used to report errors to both Sentry & amplitude
 *
 * @param error - The error to report.
 * @param eventName - The name of the error event.
 * @param errorInfo - Stack-trace information (only used by Sentry)
 */
export const reportError = (
  error: ApiError | JupiterApiError | ZodError<any> | Error,
  eventName: string,
  errorInfo?: ErrorInfo,
) => {
  let fingerprint: string[] | undefined;
  let properties: ErrorProperties = {
    error_id: nanoid(10),
    message: error.message,
    type_of_error: 'uncategorized',
  };

  if (error instanceof ApiError) {
    const apiProperties: ApiErrorProperties = {
      ...properties,
      message: error.details.message,
      type_of_error: error.details.name.toLowerCase(),
      zid: typeof error.details.data?.zid === 'string' ? error.details.data.zid : undefined,
      ...error.context,
    };

    properties = apiProperties;
  } else if (error instanceof ClientError) {
    properties.type_of_error = error.type;
    fingerprint = prepareFingerprint(error);
  } else if (error instanceof Error) {
    fingerprint = prepareFingerprint(error);
  }

  // Report all errors to Amplitude
  logAmplitudeEvent({ name: eventName, properties });

  // Don't report ApiError's to Sentry, as they are unactionable
  if (error instanceof ApiError) return;
  logSentryEvent({
    message: error.message,
    level: 'error',
    exception: {
      values: [
        {
          type: error.name,
          value: error.message,
        },
      ],
    },
    contexts: {
      matchaContext: { name: eventName, ...properties },
    },
    extra: {
      errorInfo,
    },
    fingerprint,
  });
  // searchable Sentry tags
  setTag('app.error_id', properties.error_id);
  setTag('app.error_name', eventName);
  setTag('app.type_of_error', properties.type_of_error);
};

/**
 * Determines if an error should not be reported.
 */
export const shouldIgnore = (error: ApiError | JupiterApiError | ZodError<any> | Error): boolean =>
  isDev || error instanceof ApiSellAmountTooSmallError;
