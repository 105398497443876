import { useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { fetchTokensByAddresses } from '@/utils/0x/token-registry';
import { ChainAddress } from '@/utils/models';
import { TokenInfo } from '@/utils/token.types';

import { tokensSelector, useTokenStore } from '../store/token';
import type {
  TokenResult,
  TokensByAddressesQuery,
  TokensData,
} from '../utils/0x/token-registry.types';
import { routes } from '../utils/routes';
import { objectToQueryString } from '../utils/string';
import { fetcher } from '../utils/swr';

/**
 * Get token result for a token by contract address and chain ID
 * @param tokenAddresses - the tokenAddress to fetch the result for
 * @param chainId - the id of the chain to fetch the result for
 * @returns TokenResult | undefined
 */
export default function useTokenResult(
  tokenAddress: ChainAddress | undefined,
  chainId: number | undefined,
): TokenResult | undefined {
  const { tokens, addTokens } = useTokenStore(tokensSelector);
  const token =
    tokenAddress && chainId
      ? tokens[chainId]?.[tokenAddress]?.token ??
        tokens[chainId]?.[tokenAddress.toLowerCase()]?.token
      : undefined;
  const shouldFetch = tokenAddress && chainId;

  const params: TokensByAddressesQuery | undefined = useMemo(
    () => (shouldFetch ? { addresses: [tokenAddress], chainId: [chainId] } : undefined),
    [chainId, shouldFetch, tokenAddress],
  );

  const url = useMemo(
    () => (params ? `${routes.api.SEARCH_TOKENS}${objectToQueryString(params)}` : undefined),
    [params],
  );

  const { data } = useSWR(shouldFetch ? url : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  // We have a massive amount of side effects when adding tokens.
  // We need to defer the adding of the new token as we otherwise might cause a disruption in the render order
  useEffect(() => {
    if (data && 'data' in data) {
      const tokens = (data as TokensData).data.map((tokenResult) => ({
        ...tokenResult,
        chainId: tokenResult.chainId,
      }));
      addTokens(tokens);
    }
  }, [data, addTokens]);

  return token;
}

/**
 * Fetch a token result
 * @param tokenInfo - Token info
 * @returns TokenResult
 */
export async function fetchTokenResult(tokenInfo: TokenInfo): Promise<TokenResult> {
  const response = await fetchTokensByAddresses({
    addresses: [tokenInfo.address],
    chainId: [tokenInfo.chainId],
  });
  if (response.type === 'error') throw response.error;
  if (!response.data.data.length) throw new Error('Token not found');
  return response.data.data[0];
}

/**
 * Fetch token result for a series of token infos
 * @param tokenInfos - Token infos objects
 * @returns TokenResult[]
 */
export async function fetchTokenResults(tokenInfos: TokenInfo[]): Promise<TokenResult[]> {
  if (!tokenInfos.length) return [];

  const response = await fetchTokensByAddresses({
    addresses: tokenInfos.map((info) => info.address),
    chainId: tokenInfos.map((info) => info.chainId),
  });
  if (response.type === 'error') throw response.error;
  return response.data.data;
}
