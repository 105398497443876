import type { StyleRule } from '@vanilla-extract/css';

export const lightModeClass = 'light';
export const darkModeClass = 'dark';

interface ResponsiveStyles {
  tablet?: StyleRule;
  desktop?: StyleRule;
}

type ThemedResponsiveStyles = { mobile?: StyleRule; tablet?: StyleRule; desktop?: StyleRule };
interface ThemedStyles {
  lightMode: StyleRule | ThemedResponsiveStyles;
  darkMode: StyleRule | ThemedResponsiveStyles;
}

export const responsiveStyle = ({ tablet, desktop }: ResponsiveStyles) => ({
  '@media': {
    'screen and (min-width: 768px)': tablet || {},
    'screen and (min-width: 1024px)': desktop || {},
  },
});

export const breakpoints = {
  mobile: '(max-width:767px)',
  tablet: '(min-width: 768px)',
  desktop: '(min-width: 1024px)',
};

function isThemedResponsiveStyle(obj: unknown): obj is ThemedResponsiveStyles {
  if (!obj || typeof obj != 'object') return false;
  return 'mobile' in obj || 'tablet' in obj || 'desktop' in obj;
}

/**
 *
 * This function handles boilerplate to deal with styles that need to be manually set
 * without interaction with the global style layer (for example changing images, dynamic colors and so on).
 * This function also handles existence (or nonexistence) of the darkmode feature flag.
 *
 * This function supports two modes.
 * One "general" mode that only cares about the differenciation between light and dark mode
 * and a mode that also differentiates screen sizes
 *
 */
export const themedStyles = ({ lightMode, darkMode }: ThemedStyles) => {
  if (!lightMode && !darkMode) return {};

  let lightModeStyles: Record<string, any> = {};
  let darkModeStyles: Record<string, any> = {};
  let systemModeStyles: Record<string, any> = { '@media': {} };

  // check if we care about responsive styling
  if (isThemedResponsiveStyle(lightMode)) {
    lightModeStyles['@media'] = {};
    // lightmode styles
    if (lightMode.mobile) {
      lightModeStyles['@media'][`screen and ${breakpoints.mobile}`] = lightMode.mobile;
      systemModeStyles['@media'][
        `screen and (prefers-color-scheme: light) and ${breakpoints.mobile}`
      ] = lightMode.mobile;
    }
    if (lightMode.tablet) {
      lightModeStyles['@media'][`screen and ${breakpoints.tablet}`] = lightMode.tablet;
      systemModeStyles['@media'][
        `screen and (prefers-color-scheme: light) and ${breakpoints.tablet}`
      ] = lightMode.tablet;
    }
    if (lightMode.desktop) {
      lightModeStyles['@media'][`screen and ${breakpoints.desktop}`] = lightMode.desktop;
      systemModeStyles['@media'][
        `screen and (prefers-color-scheme: light) and ${breakpoints.desktop}`
      ] = lightMode.desktop;
    }
  } else {
    // lightMode styles
    lightModeStyles = lightMode;

    // system styles
    systemModeStyles['@media']['screen and (prefers-color-scheme: light)'] = lightMode;
  }

  // dark mode
  if (isThemedResponsiveStyle(darkMode)) {
    darkModeStyles['@media'] = {};

    if (darkMode.mobile) {
      darkModeStyles['@media'][`screen and ${breakpoints.mobile}`] = darkMode.mobile;
      systemModeStyles['@media'][
        `screen and (prefers-color-scheme: dark) and ${breakpoints.mobile}`
      ] = darkMode.mobile;
    }
    if (darkMode.tablet) {
      darkModeStyles['@media'][`screen and ${breakpoints.tablet}`] = darkMode.tablet;
      systemModeStyles['@media'][
        `screen and (prefers-color-scheme: dark) and ${breakpoints.tablet}`
      ] = darkMode.tablet;
    }
    if (darkMode.desktop) {
      darkModeStyles['@media'][`screen and ${breakpoints.desktop}`] = darkMode.desktop;
      systemModeStyles['@media'][
        `screen and (prefers-color-scheme: dark) and ${breakpoints.desktop}`
      ] = darkMode.desktop;
    }
  } else {
    darkModeStyles = darkMode;
    systemModeStyles['@media']['screen and (prefers-color-scheme: dark)'] = darkMode;
  }

  return {
    selectors: {
      'body[data-theme="system"] &': systemModeStyles,
      'body &, body[data-theme="light"] &': lightModeStyles,
      'body[data-theme="dark"] &': darkModeStyles,
    },
  };
};
