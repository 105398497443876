import Link from 'next/link';

import ExternalLink from '../../../ui/Link/ExternalLink';
import Logo from '../../../ui/Logo/Logo';
import LogoCup from '../../../ui/Logo/LogoCup';
import { EVENT_NAME, logEvent } from '../../../utils/amplitude';
import { routes } from '../../../utils/routes';
import ByZeroExPill from '../Utils/ByZeroExPill';
import {
  byZeroExClass,
  containerClass,
  contentClass,
  linkClass,
  linksClass,
  logoClass,
  logoCupClass,
  logoLinkClass,
} from './index.css';

const Footer = () => {
  return (
    <footer className={containerClass}>
      <div className={contentClass}>
        <div className={logoClass}>
          <Link href={{ pathname: routes.INDEX }}>
            <div className={logoLinkClass}>
              <LogoCup className={logoCupClass} />
              <Logo height={16} />
            </div>
          </Link>
          <ByZeroExPill className={byZeroExClass} variant="gray40" />
        </div>
        <ul className={linksClass}>
          <li>
            <ExternalLink
              className={linkClass}
              href={routes.HELP}
              onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_PAGE_BOTTOM })}
            >
              Help
            </ExternalLink>
          </li>
          <li>
            <ExternalLink
              className={linkClass}
              href={routes.BLOG}
              onClick={() => logEvent({ name: EVENT_NAME.CLICK_BLOG_PAGE_BOTTOM })}
            >
              Blog
            </ExternalLink>
          </li>
          <li>
            <span className={linkClass}>
              <Link
                href={{ pathname: routes.TERMS }}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_TERMS_PAGE_BOTTOM })}
              >
                Terms
              </Link>
            </span>
          </li>
          <li>
            <span className={linkClass}>
              <Link
                href={{ pathname: routes.PRIVACY }}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_PRIVACY_PAGE_BOTTOM })}
              >
                Privacy
              </Link>
            </span>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
