export const truncate = (str: string, num: number, ellipsis: boolean): string => {
  if (!str) return '';
  return str.length > num ? `${str.substring(0, num)}${ellipsis ? '…' : ''}` : str;
};

/**
 * @param str - floating point number as string type
 * @param num - precision decimals
 * @returns (str) formatted to (num) decimals
 */
export const truncateDecimals = (str: string, num: number) => {
  if (!str) return '';
  const [int, decimals] = str.split('.');
  return decimals ? `${int}.${decimals.substring(0, num)}` : int;
};

export const objectToQueryString = (query: { [key: string]: any }) =>
  Object.entries(query)
    .filter(([, val]) => typeof val !== 'undefined')
    .reduce((str, [key, val], i) => `${str}${i === 0 ? '' : '&'}${key}=${val}`, '?');

// Some environments have issues with RegEx that contain back-tracking, so we cannot
// use them.
export function commify(value: string | number): string {
  const comps = String(value).split('.');

  if (
    comps.length > 2 ||
    !comps[0].match(/^-?[0-9]*$/) ||
    (comps[1] && !comps[1].match(/^[0-9]*$/)) ||
    value === '.' ||
    value === '-.'
  ) {
    throw new Error(`invalid value: "value", ${value}`);
  }

  // Make sure we have at least one whole digit (0 if none)
  let whole = comps[0];

  let negative = '';
  if (whole.substring(0, 1) === '-') {
    negative = '-';
    whole = whole.substring(1);
  }

  // Make sure we have at least 1 whole digit with no leading zeros
  while (whole.substring(0, 1) === '0') {
    whole = whole.substring(1);
  }
  if (whole === '') {
    whole = '0';
  }

  let suffix = '';
  if (comps.length === 2) {
    suffix = '.' + (comps[1] || '0');
  }
  while (suffix.length > 2 && suffix[suffix.length - 1] === '0') {
    suffix = suffix.substring(0, suffix.length - 1);
  }

  const formatted = [];
  while (whole.length) {
    if (whole.length <= 3) {
      formatted.unshift(whole);
      break;
    } else {
      const index = whole.length - 3;
      formatted.unshift(whole.substring(index));
      whole = whole.substring(0, index);
    }
  }

  return negative + formatted.join(',') + suffix;
}

export const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);
