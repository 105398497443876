import { createConfig } from '@privy-io/wagmi';
import { QueryClient } from '@tanstack/react-query';
import { HttpTransport } from 'viem';
import { http } from 'viem';
import {
  arbitrum,
  avalanche,
  base,
  bsc,
  mainnet,
  optimism,
  polygon,
  scroll,
  blast,
  linea,
  mantle,
  mode,
  unichain,
} from 'wagmi/chains';

import { ClientConfigurationError } from '@/lib/errors/client';

import { SUPPORTED_CHAINS, SupportedChainIDs } from '@/constants/chain';
import { JWT_PROTECTED_JSON_RPC_HTTP_URL_BY_CHAIN_ID } from '@/constants/rpc';

import { getNodeProviderJwt } from '@/hooks/useNodeProviderToken';

if (!process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID) {
  throw new ClientConfigurationError('A WalletConnect project ID is required.');
}

export const queryClient = new QueryClient();

export const config = createConfig({
  chains: [
    mainnet,
    polygon,
    bsc,
    arbitrum,
    optimism,
    avalanche,
    base,
    scroll,
    blast,
    linea,
    mantle,
    mode,
    unichain,
  ],
  transports: getTransports(),
  ssr: true,
});

/**
 * Creates a mapping of chain IDs to `HttpTransport` objects for supported Ethereum chains.
 *
 * The function sets up an `onFetchRequest` handler for each transport, which manages JWT authentication dynamically.
 *
 * Some notes about the JWT handling within the `http` transport:
 * - Retrieves the JWT from the browser's `localStorage`.
 * - The JWT is stored in `localStorage` because it refreshes frequently (e.g., every 5 minutes) and needs to be accessed dynamically.
 * - Dynamically reading the JWT from `localStorage` within the `onFetchRequest` handler prevents creating a new Wagmi configuration for each request, which would disconnect the user.
 * - Adds the JWT as a `Bearer` token in the `Authorization` header for each HTTP request when the `jwt-node-provider` flag is present in the URL.
 * - Falls back to the default RPC URL if the JWT is invalid, missing, or expired.
 * - If the `jwt-node-provider` flag is not present, it proceeds without adding the JWT.
 */
function getTransports() {
  return SUPPORTED_CHAINS.reduce<Record<SupportedChainIDs, HttpTransport>>(
    (transportsByChainId, chain) => {
      const chainId = chain.id;
      const rpcUrl = JWT_PROTECTED_JSON_RPC_HTTP_URL_BY_CHAIN_ID[chainId];
      const onFetchRequest = (_: Request, init: RequestInit) => {
        try {
          return {
            ...init,
            headers: { ...init.headers, Authorization: `Bearer ${getNodeProviderJwt()}` },
          };
        } catch (error) {
          return { ...init, url: chain.rpcUrls.default.http[0] };
        }
      };

      transportsByChainId[chainId] = http(rpcUrl, { onFetchRequest });

      return transportsByChainId;
    },
    {},
  );
}
