import { PropsWithChildren, useEffect } from 'react';
import { Config } from 'wagmi';
import { connect } from 'wagmi/actions';

import { trackError } from '@/utils/errors';

export function FarcasterFrameProvider({
  config: wagmiConfig,
  children,
}: { config: Config } & PropsWithChildren) {
  /**
   * Autoconnect warpcast-connected wallet if running app in a frame
   */
  useEffect(() => {
    const init = async () => {
      const FrameSDK = (await import('@farcaster/frame-sdk')).default;
      const context = await FrameSDK.context;

      // Autoconnect if running in a frame.
      if (context?.client.clientFid) {
        const farcasterFrame = (await import('@farcaster/frame-wagmi-connector')).default;
        connect(wagmiConfig, { connector: farcasterFrame() });
      }

      // Hide splash screen after UI renders.
      setTimeout(() => {
        FrameSDK.actions.ready();
      }, 500);
    };
    init();
  }, [wagmiConfig]);

  /**
   * Trigger 'Add Frame' sheet on first load in a frame
   *
   * @remarks
   *
   * Asks the user to add the frame to the Farcaster app, which allows
   * the user to invoke the frame from a cast, composer, or other locations in the app.
   * Also allows the app to send notifications to the user.
   * https://docs.farcaster.xyz/developers/frames/v2/spec#feature-add-frame
   */
  useEffect(() => {
    const triggerAddFrame = async () => {
      const FrameSDK = (await import('@farcaster/frame-sdk')).default;
      const context = await FrameSDK.context;

      if (context?.client.clientFid) {
        try {
          const addFrameResponse = await FrameSDK.actions.addFrame();

          console.log('add frame response', addFrameResponse);
        } catch (err: unknown) {
          console.log('add frame error', err);

          if (err instanceof Error) {
            trackError(err);
          }
        }
      }
    };

    triggerAddFrame();
  }, []);

  return <>{children}</>;
}
