import { trackError } from '@/utils/errors';

// https://github.com/facebook/react/issues/11538#issuecomment-417504600
declare global {
  interface Node {
    removeChild<T extends Node>(child: T): T;
    insertBefore<T extends Node>(newNode: T, referenceNode: Node | null): T;
  }
}

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function <T extends Node>(this: Node, child: T): T {
    if (child.parentNode !== this) {
      if (console) {
        console.debug('Cannot remove a child from a different parent', child, this);
        trackError(new Error('Cannot remove a child from a different parent'));
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments as any) as T;
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function <T extends Node>(
    this: Node,
    newNode: T,
    referenceNode: Node | null,
  ): T {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.debug(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this,
        );
        trackError(new Error('Cannot insert before a reference node from a different parent'));
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments as any) as T;
  };
}
