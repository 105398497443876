import { PublicKey, TransactionSignature } from '@solana/web3.js';
import {
  isAddress as _isEthereumAddress,
  Address as _EthereumAddress,
  Hash as _EthereumHash,
  isHash as _isEthereumHash,
} from 'viem';

// TODO: When Privy's react-auth upgrades @solana/web3.js to 2.0+, we can remove
// this and import `Address` (as SolanaAddress) directly. Track the fix in this Linear:
// https://linear.app/0xproject/issue/MAT-4479/upgrade-to-solanaweb3js-v2
export type SolanaAddress<TAddress extends string = string> = TAddress & {
  readonly __brand: unique symbol;
};

export type EthereumAddress = _EthereumAddress;

// Address type for any supported chain in the app
export type ChainAddress = EthereumAddress | SolanaAddress;

export const isEthereumAddress = (address: string) => {
  return _isEthereumAddress(address);
};

export function isSolanaAddress(address: string): boolean {
  try {
    new PublicKey(address);
    return true;
  } catch {
    return false;
  }
}

// Check that the address is a valid address of any chain supported by the app
export const isChainAddress = (address: string): address is ChainAddress => {
  return isEthereumAddress(address) || isSolanaAddress(address);
};

export type EthereumHash = _EthereumHash;

export type SolanaHash = TransactionSignature;

export type ChainHash = EthereumHash | SolanaHash;

export const isEthereumHash = (value: string): value is EthereumHash => {
  return _isEthereumHash(value);
};
