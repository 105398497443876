import { createContext, ReactNode, useState } from 'react';

export const SankeyContext = createContext<{
  setSankeyModalOpen: (openState: boolean) => void;
  isOpen: boolean;
  liquiditySourceOnHoverInLegend?: string;
  onLegendHover: (value: string | undefined) => void;
}>({
  setSankeyModalOpen: () => {},
  isOpen: false,
  liquiditySourceOnHoverInLegend: undefined,
  onLegendHover: () => {},
});

export function SankeyProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [liquiditySourceOnHoverInLegend, onLegendHover] = useState<string | undefined>(undefined);
  return (
    <SankeyContext.Provider
      value={{
        isOpen,
        setSankeyModalOpen: setIsOpen,
        liquiditySourceOnHoverInLegend,
        onLegendHover,
      }}
    >
      {children}
    </SankeyContext.Provider>
  );
}
