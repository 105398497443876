import useSWR, { SWRResponse } from 'swr';

import { fetcher } from '@/utils/swr';

export function useScrollBadgeProgress({ address }: { address?: string }): SWRResponse<{
  numTrades: number;
  requiredTrades: number;
} | null> {
  return useSWR(address ? `/api/scroll/progress?recipient=${address}` : null, fetcher);
}
