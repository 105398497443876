import { addDays, isPast, fromUnixTime, getUnixTime } from 'date-fns';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const REFERRAL_ID_KEY = 'referrer_id';
const EXPIRATION_DAYS = 30; // 30 days

type ReferrerStoreType = {
  referrerId: string | undefined;
  referrerName: string | undefined;
  expiration: number | undefined;
  setReferrerId: (refId: string) => void;
  setReferrerName: (refName: string) => void;
  clearReferrerId: () => void;
  isExpired: () => boolean;
};

export const useReferrerStore = createWithEqualityFn<ReferrerStoreType>()(
  persist(
    (set, get) => ({
      referrerId: undefined,
      expiration: undefined,
      referrerName: undefined,
      setReferrerId: (refId: string) =>
        set({
          referrerId: refId,
          expiration: getUnixTime(addDays(new Date(), EXPIRATION_DAYS)),
        }),
      setReferrerName: (refName: string) =>
        set({
          referrerName: refName,
        }),
      clearReferrerId: () =>
        set({ referrerId: undefined, expiration: undefined, referrerName: undefined }),
      isExpired: () => {
        const expiration = get().expiration;
        return expiration ? isPast(fromUnixTime(expiration)) : true;
      },
    }),
    {
      name: REFERRAL_ID_KEY,
    },
  ),
  shallow,
);

export const referrerIdSelector = (state: ReferrerStoreType) => ({
  referrerId: state.referrerId,
});

export const referrerNameSelector = (state: ReferrerStoreType) => ({
  referrerName: state.referrerName,
  setReferrerName: state.setReferrerName,
});

export const referrersStoreSelectors = (state: ReferrerStoreType) => ({
  expiration: state.expiration,
  referrerId: state.referrerId,
  setReferrerId: state.setReferrerId,
  clearReferrerId: state.clearReferrerId,
  isExpired: state.isExpired,
});
