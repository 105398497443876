import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

// Template: Add your feature flag here
// export const YOUR_FLAG_NAME_COOKIE_KEY = 'yourFlagName';
export const SOLANA_PHASE1_COOKIE_KEY = 'solanaPhase1';
export const NFT_FALL_COOKIE_KEY = 'nftFall';
export const SEARCH_V2_COOKIE_KEY = 'searchV2';

/// List of supported feature flags.
const SUPPORTED_FEATURE_FLAGS: string[] = [
  // feature flags here
  // YOUR_FLAG_NAME_COOKIE_KEY,
  SOLANA_PHASE1_COOKIE_KEY,
  NFT_FALL_COOKIE_KEY,
  SEARCH_V2_COOKIE_KEY,
];

/**
 * This hook implements a *pseudo feature toggle* and
 * can be removed if no longer being used.
 * @param cookieKey - Cookie key to store the feature toggle.
 */
export function useFeature(cookieKey: string) {
  const [featureEnabled, setFeatureEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (!SUPPORTED_FEATURE_FLAGS.includes(cookieKey)) {
      console.warn(`Feature flag "${cookieKey}" is not supported.`);
      return;
    }

    const { search } = window.location;
    const enableFeature = search?.includes(`${cookieKey}=true`);
    const disableFeature = search?.includes(`${cookieKey}=false`);
    const toggleOnPersisted = Cookies.get(cookieKey) === 'true';

    // Example of how to enable a feature flag by default
    // if (!search || !search.includes(`${YOUR_FLAG_NAME_COOKIE_KEY}=`)) {
    //   // Active by default as a way to release the feature
    //   setFeatureEnabled(true);
    // }

    if (disableFeature) {
      Cookies.remove(cookieKey);
      setFeatureEnabled(false);
    } else if (enableFeature || toggleOnPersisted) {
      Cookies.set(cookieKey, 'true', { expires: 14 });
      setFeatureEnabled(true);
    }
  }, [cookieKey]);

  return featureEnabled;
}

/**
 * Enabled feature flags list.
 * @returns Feature flags names.
 */
export const useEnabledFeatures = () => {
  const [flags, setFlags] = useState<string[]>([]);

  useEffect(() => {
    setFlags(SUPPORTED_FEATURE_FLAGS.filter((feature) => Cookies.get(feature) === 'true'));
  }, []);

  return flags;
};
