import { useEffect } from 'react';

/**
 * A custom React hook that hides and shows the Intercom messenger.
 *
 * This hook relies on the presence of the `Intercom` global object and manipulates the
 * visibility of the Intercom launcher based on the `isHidden` parameter. It also targets an
 * element with the class name `live-chat-loader-placeholder` to manually adjust its display
 * property, allowing for additional control over the chat interface's visibility.
 */
export function useHideShowIntercom(isHidden?: boolean) {
  useEffect(() => {
    if (!isHidden) return;

    const [intercomPlaceholder] = document.getElementsByClassName('live-chat-loader-placeholder');
    if (intercomPlaceholder) {
      intercomPlaceholder.setAttribute('style', 'display: none !important');
    }

    // Intercom settings
    window.intercomSettings = {
      hide_default_launcher: true,
    };
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }

    return () => {
      if (intercomPlaceholder) {
        intercomPlaceholder.setAttribute('style', 'display: block !important');
      }

      window.intercomSettings = {
        hide_default_launcher: false,
      };
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false,
        });
      }
    };
  }, [isHidden]);
}
