import { TokenPairFeesResponse } from '@/pages/api/tokens/fees';
import { Address } from 'viem';

import { ClientNetworkError } from '@/lib/errors/client';

import { CHAIN_IDS } from '@/constants/chain';

import { getBaseURL, isTest } from '@/utils/environment';
import { routes } from '@/utils/routes';
import { objectToQueryString } from '@/utils/string';

export const STABLES_PER_CHAIN = {
  [CHAIN_IDS.MAINNET]: {
    USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    TUSD: '0x0000000000085d4780B73119b644AE5ecd22b376',
    USDP: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    LUSD: '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
    GUSD: '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
  },
  [CHAIN_IDS.MATIC]: {
    USDC: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    USDCE: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    USDT: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    DAI: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    TUSD: '0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756',
  },
  [CHAIN_IDS.BSC]: {
    USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    DAI: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    TUSD: '0x14016e85a25aeb13065688cafb43044c2ef86784',
    USDP: '0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094',
  },
};

const STABLE_PAIR_BIPS = 2;
const STANDARD_PAIR_BIPS = 25;

/**
 * Get the fee amount for a limit order pair (in Bips)
 * @param tokenA - token address of the first token in the pair
 * @param tokenB - token address of the second token in the pair
 * @param chainId - chainId for the pair
 */
export const getLimitOrderFeeBips = async (
  tokenA: Address,
  tokenB: Address,
  chainId: number,
): Promise<number | undefined> => {
  try {
    const response = await fetch(
      `${isTest ? getBaseURL() : ''}${routes.api.TOKEN_FEE_CONFIG}${objectToQueryString({
        tokenAddressA: tokenA,
        tokenAddressB: tokenB,
        chainIdA: chainId,
        chainIdB: chainId,
      })}`,
    );

    if (!response.ok) throw new ClientNetworkError(response.statusText);

    const feeTokenData: TokenPairFeesResponse = await response.json();

    if (feeTokenData && feeTokenData.lo) {
      if (feeTokenData.isStablecoinPair) {
        return STABLE_PAIR_BIPS;
      }

      return STANDARD_PAIR_BIPS;
    }
  } catch (err: unknown) {
    console.debug('error fetching limit order fee data', err);
    throw err;
  }

  return undefined;
};
