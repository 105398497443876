import ENV from '@/environment';

import { isTest } from '@/utils/environment';
import { trackError } from '@/utils/errors';

import { CHAIN_IDS, SUPPORTED_CHAINS, SUPPORTED_CHAINS_BY_CHAIN_ID } from './chain';

const ALCHEMY_MAINNET_RPC_KEY = isTest ? ENV.ALCHEMY_INTEGRATION_TEST_API_KEY : ENV.ALCHEMY_RPC_KEY;

// supported chains RPC endpoints
export const JSON_RPC_URL_MAINNET_HTTP = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_MAINNET_RPC_KEY}`;
export const JSON_RPC_URL_MATIC_HTTP = `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_MAINNET_RPC_KEY}`;
export const JSON_RPC_URL_OPTIMISM_HTTP = `https://opt-mainnet.g.alchemy.com/v2/${ALCHEMY_MAINNET_RPC_KEY}`;

// supported chains RPC endpoints for JWT
export const JSON_RPC_URL_MAINNET_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://sly-falling-tab.quiknode.pro/'
    : 'https://thrilling-frequent-sky.quiknode.pro/';
export const JSON_RPC_URL_MATIC_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://convincing-tiniest-wave.matic.quiknode.pro/'
    : 'https://thrumming-proportionate-patron.matic.quiknode.pro/';
export const JSON_RPC_URL_OPTIMISM_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://quaint-multi-telescope.optimism.quiknode.pro/'
    : 'https://distinguished-billowing-spring.optimism.quiknode.pro/';
export const JSON_RPC_URL_ARBITRUM_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://summer-few-uranium.arbitrum-mainnet.quiknode.pro/'
    : 'https://responsive-lingering-dust.arbitrum-mainnet.quiknode.pro/';
export const JSON_RPC_URL_AVAX_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://delicate-bold-pallet.avalanche-mainnet.quiknode.pro/ext/bc/C/rpc/'
    : 'https://special-winter-theorem.avalanche-mainnet.quiknode.pro/ext/bc/C/rpc/';
export const JSON_RPC_URL_BASE_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://withered-side-frost.base-mainnet.quiknode.pro/'
    : 'https://serene-cosmopolitan-mansion.base-mainnet.quiknode.pro/';
export const JSON_RPC_URL_BSC_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://thrumming-broken-dew.bsc.quiknode.pro/'
    : 'https://practical-long-crater.bsc.quiknode.pro/';
export const JSON_RPC_URL_SCROLL_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://special-autumn-darkness.scroll-mainnet.quiknode.pro/'
    : 'https://powerful-snowy-fire.scroll-mainnet.quiknode.pro/';
export const JSON_RPC_URL_BLAST_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://tiniest-bitter-grass.blast-mainnet.quiknode.pro/'
    : 'https://palpable-cosmopolitan-emerald.blast-mainnet.quiknode.pro/';
export const JSON_RPC_URL_LINEA_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://methodical-crimson-market.linea-mainnet.quiknode.pro/'
    : 'https://evocative-hidden-borough.linea-mainnet.quiknode.pro/';
export const JSON_RPC_URL_MANTLE_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://capable-proud-arrow.mantle-mainnet.quiknode.pro/'
    : 'https://necessary-warmhearted-glade.mantle-mainnet.quiknode.pro/';
export const JSON_RPC_URL_MODE_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://patient-burned-replica.mode-mainnet.quiknode.pro/'
    : 'https://delicate-practical-snow.mode-mainnet.quiknode.pro/';
export const JSON_RPC_URL_SOLANA_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://fabled-still-tab.solana-mainnet.quiknode.pro/'
    : 'https://chaotic-convincing-sun.solana-mainnet.quiknode.pro/';
export const JSON_RPC_URL_UNICHAIN_HTTP_JWT =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'https://sleek-hidden-hill.unichain-mainnet.quiknode.pro/'
    : 'https://intensive-evocative-violet.unichain-mainnet.quiknode.pro/';

export const JWT_PROTECTED_JSON_RPC_HTTP_URL_BY_CHAIN_ID: Record<
  keyof typeof SUPPORTED_CHAINS_BY_CHAIN_ID,
  string
> = {
  [CHAIN_IDS.MAINNET]: JSON_RPC_URL_MAINNET_HTTP_JWT,
  [CHAIN_IDS.ARBITRUM]: JSON_RPC_URL_ARBITRUM_HTTP_JWT,
  [CHAIN_IDS.AVALANCHE]: JSON_RPC_URL_AVAX_HTTP_JWT,
  [CHAIN_IDS.BASE]: JSON_RPC_URL_BASE_HTTP_JWT,
  [CHAIN_IDS.BSC]: JSON_RPC_URL_BSC_HTTP_JWT,
  [CHAIN_IDS.MATIC]: JSON_RPC_URL_MATIC_HTTP_JWT,
  [CHAIN_IDS.OPTIMISM]: JSON_RPC_URL_OPTIMISM_HTTP_JWT,
  [CHAIN_IDS.SCROLL]: JSON_RPC_URL_SCROLL_HTTP_JWT,
  [CHAIN_IDS.BLAST]: JSON_RPC_URL_BLAST_HTTP_JWT,
  [CHAIN_IDS.LINEA]: JSON_RPC_URL_LINEA_HTTP_JWT,
  [CHAIN_IDS.MANTLE]: JSON_RPC_URL_MANTLE_HTTP_JWT,
  [CHAIN_IDS.MODE]: JSON_RPC_URL_MODE_HTTP_JWT,
  [CHAIN_IDS.SOLANA]: JSON_RPC_URL_SOLANA_HTTP_JWT,
  [CHAIN_IDS.UNICHAIN]: JSON_RPC_URL_UNICHAIN_HTTP_JWT,
};

// default refresh interval for getting price & chart data in milliseconds
export const REFRESH_INTERVAL = 30_000;

// Make sure we have an RPC url defined for all chains
if (process.env.NODE_ENV !== 'test') {
  for (const chain of SUPPORTED_CHAINS) {
    if (!JWT_PROTECTED_JSON_RPC_HTTP_URL_BY_CHAIN_ID[chain.id]) {
      if (process.env.NODE_ENV === 'production') {
        trackError(new Error(`RPC url missing for chain: ${chain.name}`));
      }
      console.error(`RPC URL missing for chain: ${chain.name}`);
    }
  }
}
