import { SUPPORTED_CHAINS } from '@/constants/chain';

export const ROOT_URLS = {
  development: 'http://localhost:3000',
  preview: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
  production: `https://${process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL}`,
};
export const ROOT_URL = ROOT_URLS[process.env.NEXT_PUBLIC_VERCEL_ENV];

// Default - Home Page
export const DEFAULT_SITE_TITLE = 'Matcha | DEX aggregator by 0x | Search, trade, done.';
export const DEFAULT_SITE_DESCRIPTION = `Matcha DEX aggregator | Search and trade over +9 million tokens across +100 exchanges on ${SUPPORTED_CHAINS.length} chains. Trade now.`;
const DEFAULT_SITE_NAME = 'Matcha';
export const ROOT_URL_PRODUCTION = 'https://matcha.xyz';
const TWITTER_HANDLE = '@matchaxyz';

export const DEFAULT_SEO_CONFIG = {
  title: DEFAULT_SITE_TITLE,
  description: DEFAULT_SITE_DESCRIPTION,
  canonical: ROOT_URL_PRODUCTION,
  robotsProps: {
    notranslate: false,
    noarchive: true,
    nosnippet: false,
    maxSnippet: DEFAULT_SITE_DESCRIPTION.length + 3,
  },
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: ROOT_URL_PRODUCTION,
    title: DEFAULT_SITE_TITLE,
    description: DEFAULT_SITE_DESCRIPTION,
    site_name: DEFAULT_SITE_NAME,
    images: [
      {
        url: `${ROOT_URL_PRODUCTION}/images/og/social-banner.jpg`,
        width: 1200,
        height: 630,
        alt: 'Matcha',
      },
    ],
  },
  twitter: {
    handle: TWITTER_HANDLE,
    site: TWITTER_HANDLE,
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'twitter:title',
      content: DEFAULT_SITE_TITLE,
    },
    {
      name: 'twitter:description',
      content: DEFAULT_SITE_DESCRIPTION,
    },
    {
      name: 'twitter:image',
      content: `${ROOT_URL_PRODUCTION}/images/og/social-banner.jpg`,
    },
    {
      name: 'twitter:url',
      content: ROOT_URL_PRODUCTION,
    },
  ],
};
