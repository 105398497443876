import { MouseEvent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  href: string;
  className?: string;
  style?: { [key: string]: string };
  onClick?: (e: MouseEvent) => void;
}

const ExternalLink = ({ children, className, href, style, onClick }: Props) => {
  return (
    <a
      className={className || ''}
      href={href}
      target="_blank"
      rel="noreferrer"
      style={style}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
