import { defineChain } from 'viem';
import {
  type Chain,
  mainnet,
  polygon,
  arbitrum,
  avalanche,
  base,
  bsc,
  optimism,
  mode,
  scroll,
  blast,
  linea,
  mantle,
  unichain,
} from 'wagmi/chains';

import { ClientConfigurationError } from '@/lib/errors/client';

const CHAIN_ID_SOLANA = 1399811149;

export const solana = defineChain({
  name: 'Solana',
  id: CHAIN_ID_SOLANA,
  sourceId: CHAIN_ID_SOLANA,
  nativeCurrency: { name: 'Solana', symbol: 'SOL', decimals: 9 },
  rpcUrls: {
    default: {
      http: ['https://api.mainnet-beta.solana.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Solscan',
      url: 'https://solscan.io/',
      apiUrl: 'https://pro-api.solscan.io/pro-api-docs/v2.0',
    },
  },
});

export const CHAIN_IDS = {
  SOLANA: solana.id,
  MAINNET: mainnet.id,
  BASE: base.id,
  UNICHAIN: unichain.id,
  BSC: bsc.id,
  MATIC: polygon.id,
  AVALANCHE: avalanche.id,
  ARBITRUM: arbitrum.id,
  OPTIMISM: optimism.id,
  SCROLL: scroll.id,
  BLAST: blast.id,
  LINEA: linea.id,
  MANTLE: mantle.id,
  MODE: mode.id,
} as const;

// source: https://dune.com/queries/3285438/5499741
export const BLOCK_CONFIRMATION_TIME_IN_SECONDS: {
  [key: keyof typeof SUPPORTED_CHAINS_BY_CHAIN_ID]: number;
} = {
  [CHAIN_IDS.MAINNET]: 12,
  [CHAIN_IDS.BSC]: 3,
  [CHAIN_IDS.MATIC]: 2.3,
  [CHAIN_IDS.AVALANCHE]: 2,
  [CHAIN_IDS.ARBITRUM]: 0.26,
  [CHAIN_IDS.OPTIMISM]: 2,
  [CHAIN_IDS.BASE]: 2,
  [CHAIN_IDS.SCROLL]: 2.9,
  [CHAIN_IDS.BLAST]: 2.1,
  [CHAIN_IDS.LINEA]: 2.02,
  [CHAIN_IDS.MANTLE]: 2,
  [CHAIN_IDS.MODE]: 2,
  [CHAIN_IDS.SOLANA]: 0.4,
  [CHAIN_IDS.UNICHAIN]: 1,
};

export const SUPPORTED_CHAINS: Chain[] = [
  mainnet,
  base,
  unichain,
  arbitrum,
  polygon,
  bsc,
  optimism,
  linea,
  avalanche,
  blast,
  mode,
  scroll,
  mantle,
];

/**
 * TODO: MAT-4376
 * Once we migrate to Solana, we can consolidate the chains into a single array
 * named "SUPPORTED_CHAINS." For now, "SUPPORTED_CHAINS_V2" is used when the
 * Solana feature flag is enabled.
 */
export const SUPPORTED_CHAINS_V2 = [solana, ...SUPPORTED_CHAINS];

export const LIMIT_ORDER_SUPPORTED_CHAINS: Chain[] = [mainnet, polygon, bsc];

const CROSS_CHAIN_CHAINS: Chain[] = [
  mainnet,
  polygon,
  arbitrum,
  base,
  unichain,
  mantle,
  blast,
  mode,
  scroll,
  linea,
  optimism,
  bsc,
  avalanche,
];

// TODO: MAT-4376 cleanup once Solana Phase 3 is completed
const CROSS_CHAIN_CHAINS_V2: Chain[] = [solana, ...CROSS_CHAIN_CHAINS];

const CROSS_CHAIN_CHAINS_SVM: Chain[] = [
  solana,
  mainnet,
  polygon,
  arbitrum,
  base,
  optimism,
  bsc,
  avalanche,
];

// Using SUPPORTED_CHAINS order to make both chain lists consistent
export const CROSS_CHAIN_SUPPORTED_CHAINS: Chain[] = SUPPORTED_CHAINS.filter((chain) =>
  CROSS_CHAIN_CHAINS.includes(chain),
);

// TODO: MAT-4376 cleanup once Solana Phase 3 is completed
export const CROSS_CHAIN_SUPPORTED_CHAINS_V2: Chain[] = SUPPORTED_CHAINS_V2.filter((chain) =>
  CROSS_CHAIN_CHAINS_V2.includes(chain),
);

export const SUPPORTED_CHAINS_BY_CHAIN_ID = SUPPORTED_CHAINS.reduce<{ [key: number]: Chain }>(
  (supportedChainsById, chain: Chain) => {
    supportedChainsById[chain.id] = chain;
    return supportedChainsById;
  },
  {},
);

// TODO: MAT-4376 Cleanup once Solana Phase 1 is completed
export const SUPPORTED_CHAINS_BY_CHAIN_ID_V2 = SUPPORTED_CHAINS_V2.reduce<{ [key: number]: Chain }>(
  (supportedChainsById, chain: Chain) => {
    supportedChainsById[chain.id] = chain;
    return supportedChainsById;
  },
  {},
);

export const SUPPORTED_CHAINS_BY_CHAIN_ID_SVM = CROSS_CHAIN_CHAINS_SVM.reduce<{
  [key: number]: Chain;
}>((supportedChainsById, chain: Chain) => {
  supportedChainsById[chain.id] = chain;
  return supportedChainsById;
}, {});

export const NATIVE_TOKEN_SYMBOL_PER_CHAIN: { [key: number]: string } = SUPPORTED_CHAINS_V2.reduce(
  (memo, chain: Chain) => {
    memo[chain.id] = chain.nativeCurrency.symbol;
    return memo;
  },
  {} as { [key: number]: string },
);

export type SupportedChainIDs = keyof typeof SUPPORTED_CHAINS_BY_CHAIN_ID;

export const CHAIN_IDS_SUPPORT_EIP1559: number[] = [
  CHAIN_IDS.BASE,
  CHAIN_IDS.MAINNET,
  CHAIN_IDS.MATIC,
  CHAIN_IDS.OPTIMISM,
  CHAIN_IDS.AVALANCHE,
  CHAIN_IDS.MODE,
  CHAIN_IDS.SCROLL,
  CHAIN_IDS.BLAST,
  CHAIN_IDS.MANTLE,
  CHAIN_IDS.UNICHAIN,
];

export const CHAIN_IDS_SUPPORT_GASLESS: number[] = [
  CHAIN_IDS.MAINNET,
  CHAIN_IDS.MATIC,
  CHAIN_IDS.ARBITRUM,
  CHAIN_IDS.BASE,
  CHAIN_IDS.BLAST,
  CHAIN_IDS.MODE,
  CHAIN_IDS.SCROLL,
  CHAIN_IDS.OPTIMISM,
  CHAIN_IDS.BSC,
  CHAIN_IDS.AVALANCHE,
  CHAIN_IDS.MANTLE,
];

export const SUPPORTED_CHAIN_IDS = SUPPORTED_CHAINS.map((chain) => chain.id);
export const SUPPORTED_CHAIN_IDS_V2 = SUPPORTED_CHAINS_V2.map((chain) => chain.id);
export const SUPPORTED_CHAIN_IDS_LIMIT_ORDERS = LIMIT_ORDER_SUPPORTED_CHAINS.map((c) => c.id);
export const SUPPORTED_CHAIN_IDS_CROSS_CHAIN = CROSS_CHAIN_SUPPORTED_CHAINS.map((c) => c.id);
export const SUPPORTED_CHAIN_IDS_CROSS_CHAIN_V2 = CROSS_CHAIN_SUPPORTED_CHAINS_V2.map((c) => c.id);
export const SUPPORTED_CHAIN_IDS_CROSS_CHAIN_SVM = CROSS_CHAIN_CHAINS_SVM.map((c) => c.id);

export const NETWORK_NAME_PER_CHAIN_ID = SUPPORTED_CHAINS.reduce<{
  [key: number]: string;
}>(
  (chainNameByChainId, chain: Chain) => {
    chainNameByChainId[chain.id] =
      chain.id === CHAIN_IDS.BSC
        ? 'BNB Chain' // override BSC name to be "BNB Chain"
        : chain.name;

    return chainNameByChainId;
  },
  { [solana.id]: solana.name },
);

/*
The three reducers defined below are used to power the URL structure.
Due to Wagmi's config, we need to define the network name for Mainnet to 'ethereum'
since Wagmi has it defined as 'homestead'. We also are doing a custom mapping for
polygon, since Wagmi's config names the network 'matic'.
*/

/** A mapping from a human readable chain name to chainId. */
export const CHAIN_ID_PER_CHAIN_NAME = SUPPORTED_CHAINS.reduce<{ [key: string]: number }>(
  (chainIdByChainName, chain: Chain) => {
    chainIdByChainName[chain.name] = chain.id;

    if (chain.id === CHAIN_IDS.BSC) {
      chainIdByChainName['BNB Chain'] = chain.id;
    }

    return chainIdByChainName;
  },
  {},
);

/**
 *
 * Mapping from network name to chain id.
 *
 */
export const CHAIN_ID_PER_NETWORK_URL_PATH = SUPPORTED_CHAINS_V2.reduce<{ [key: string]: number }>(
  (chainIdByChainName, chain: Chain) => {
    switch (chain.id) {
      case CHAIN_IDS.MAINNET:
        chainIdByChainName['ethereum'] = CHAIN_IDS.MAINNET;
        break;
      case CHAIN_IDS.MATIC:
        chainIdByChainName['polygon'] = CHAIN_IDS.MATIC;
        break;
      case CHAIN_IDS.BSC:
        chainIdByChainName['bsc'] = CHAIN_IDS.BSC;
        break;
      case CHAIN_IDS.ARBITRUM:
        chainIdByChainName['arbitrum'] = CHAIN_IDS.ARBITRUM;
        break;
      case CHAIN_IDS.OPTIMISM:
        chainIdByChainName['optimism'] = CHAIN_IDS.OPTIMISM;
        break;
      case CHAIN_IDS.AVALANCHE:
        chainIdByChainName['avalanche'] = CHAIN_IDS.AVALANCHE;
        break;
      case CHAIN_IDS.BASE:
        chainIdByChainName['base'] = CHAIN_IDS.BASE;
        break;
      case CHAIN_IDS.SCROLL:
        chainIdByChainName['scroll'] = CHAIN_IDS.SCROLL;
        break;
      case CHAIN_IDS.BLAST:
        chainIdByChainName['blast'] = CHAIN_IDS.BLAST;
        break;
      case CHAIN_IDS.LINEA:
        chainIdByChainName['linea'] = CHAIN_IDS.LINEA;
        break;
      case CHAIN_IDS.MANTLE:
        chainIdByChainName['mantle'] = CHAIN_IDS.MANTLE;
        break;
      case CHAIN_IDS.MODE:
        chainIdByChainName['mode'] = CHAIN_IDS.MODE;
        break;
      case CHAIN_IDS.SOLANA:
        chainIdByChainName['solana'] = CHAIN_IDS.SOLANA;
        break;
      case CHAIN_IDS.UNICHAIN:
        chainIdByChainName['unichain'] = CHAIN_IDS.UNICHAIN;
        break;
      default:
        throw new ClientConfigurationError('Supported chain missing slug');
    }

    return chainIdByChainName;
  },
  {},
);

export const NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID = Object.entries(
  CHAIN_ID_PER_NETWORK_URL_PATH,
).reduce<{
  [key: number]: string;
}>((chainNameByChainId, [slug, id]) => {
  chainNameByChainId[id] = slug;
  return chainNameByChainId;
}, {});

/**
 * The names of chains used by the 0x Data API.
 */
export const DATA_API_CHAIN_NAMES: Record<number, string> = {
  [CHAIN_IDS.MAINNET]: 'Ethereum',
  [CHAIN_IDS.OPTIMISM]: 'Optimism',
  [CHAIN_IDS.BASE]: 'Base',
  [CHAIN_IDS.BSC]: 'BSC',
  [CHAIN_IDS.MATIC]: 'Polygon',
  [CHAIN_IDS.ARBITRUM]: 'Arbitrum',
  [CHAIN_IDS.AVALANCHE]: 'Avalanche',
  [CHAIN_IDS.SCROLL]: 'Scroll',
  [CHAIN_IDS.BLAST]: 'Blast',
  [CHAIN_IDS.LINEA]: 'Linea',
  [CHAIN_IDS.MANTLE]: 'Mantle',
  [CHAIN_IDS.MODE]: 'Mode',
  [CHAIN_IDS.UNICHAIN]: 'Unichain',
};

/**
 * The names of block explorers by chain.
 */
export const CHAIN_EXPLORER_NAMES = SUPPORTED_CHAINS_V2.reduce<{
  [key: number]: string;
}>((explorerNames, chain) => {
  explorerNames[chain.id] = chain.blockExplorers?.default.name || 'Explorer';
  return explorerNames;
}, {});

/**
 * The chain id for Solana used by the Socket API.
 */
export const SOCKET_SOLANA_CHAIN_ID = 89999;
