import Footer from '@/components/Landing/LandingFooter';

import { btnStyles } from '@/styles/buttons.css';

import { routes } from '@/utils/routes';

import { containerClass, contentClass, titleClass } from './index.css';

const Error500 = () => {
  return (
    <div className={containerClass}>
      <div className={contentClass}>
        <h1 className={titleClass}>Something went wrong.</h1>
        <a className={btnStyles.PRIMARY_MD} href={routes.INDEX}>
          Back to home page
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Error500;
