export const update = () => {
  if (typeof window !== 'undefined' && typeof window.Intercom !== 'undefined') {
    window.Intercom('update');
  }
};

export const showNewMessage = (fallback?: () => void) => {
  if (typeof window !== 'undefined' && typeof window.Intercom !== 'undefined') {
    window.Intercom('showNewMessage');
  } else {
    fallback?.();
  }
};
