import { Component, ErrorInfo } from 'react';

import { reportError, shouldIgnore } from '@/lib/errors/report';

import Error500 from '@/components/pages/Error/500';

interface Props {
  /** The children to render */
  children: React.ReactNode;
}
interface State {
  /** Whether an error has been caught */
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (shouldIgnore(error)) return;

    reportError(error, 'root_module_error', errorInfo);
  }

  render() {
    if (this.state.hasError) return <Error500 />;

    return this.props.children;
  }
}
