import { LogTradeRequestType } from '@/pages/api/trade/log';
import { Result } from '@/types';

import { LogScrollProgressType } from '../pages/api/scroll/log';
import { trackError } from './errors';
import { routes } from './routes';

export async function logTrade(trade: LogTradeRequestType): Promise<Result<void>> {
  try {
    const result = await fetch(routes.api.LOG_TRADE, {
      method: 'post',
      body: JSON.stringify(trade),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!result.ok) {
      throw new Error(`[${result.status}]: Error while logging trade`);
    }

    return {
      type: 'success',
      data: undefined,
    };
  } catch (e) {
    const error = e instanceof Error ? e : new Error('Error while logging trade');
    trackError(error);

    return {
      type: 'error',
      error,
    };
  }
}

export async function incrementScrollProgress(trade: LogScrollProgressType): Promise<Result<void>> {
  try {
    const result = await fetch(routes.api.LOG_SCROLL_PROGRESS, {
      method: 'post',
      body: JSON.stringify(trade),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!result.ok) {
      throw new Error(`[${result.status}]: Error while logging trade`);
    }

    return {
      type: 'success',
      data: undefined,
    };
  } catch (e) {
    const error = e instanceof Error ? e : new Error('Error while logging trade');
    trackError(error);

    return {
      type: 'error',
      error,
    };
  }
}
