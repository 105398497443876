import { parseSwap } from '@0x/0x-parser';
import uniqWith from 'lodash/uniqWith';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { Chain, PublicClient, TransactionReceipt, Transport } from 'viem';
import { useBlockNumber, usePublicClient } from 'wagmi';

import { ApiError, createApiError } from '@/lib/errors/api';
import { ClientDataError, ClientNetworkError } from '@/lib/errors/client';

import useIsSmartWallet from '@/hooks/useIsSmartWallet';

import { referrerIdSelector, useReferrerStore } from '@/store/referrals';

import { isEthereumHash } from '@/utils/models';

import { CHAIN_IDS } from '../../../constants/chain';
import { useMatchaWallets } from '../../../hooks/useMatchaWallets';
import { useScrollBadgeProgress } from '../../../hooks/useScrollBadgeProgress';
import {
  completeTransactionsSelector,
  transactionsSelector,
  recentTransactionSelector,
  useTransactionStore,
} from '../../../store/transaction';
import {
  GaslessTransaction,
  SerializableTransactionReceipt,
  SwapTransaction,
  Transaction,
  WrapTransaction,
} from '../../../store/transaction/types';
import { isTransactionPending, isTransactionSuccessful } from '../../../store/transaction/utils';
import { EVENT_NAME, logTxnModuleEvent } from '../../../utils/amplitude';
import { trackError } from '../../../utils/errors';
import {
  GaslessStatusRequest,
  GaslessStatusResponse,
  GaslessSubmitResponse,
} from '../../../utils/gasless/types';
import { EthereumAddress } from '../../../utils/models';
import { routes } from '../../../utils/routes';
import { objectToQueryString } from '../../../utils/string';
import { incrementScrollProgress } from '../../../utils/trade-broadcaster';
import { getConnectedChain } from '../../../utils/wallet';

interface TransactionLoading {
  hash: string;
  lastBlockNumber: bigint;
}

/**
 * Serializes the transaction receipt data to store in the transaction store.
 *
 * @param receipt - transaction receipt without the logs and logsBloom fields.
 */
const serializeReceiptData = (
  receipt: Omit<TransactionReceipt, 'logs' | 'logsBloom'>,
): SerializableTransactionReceipt => {
  return Object.keys(receipt).reduce((memo, key) => {
    const val = (receipt as { [key: string]: any })[key];
    if (typeof val === 'bigint') {
      memo[key] = val.toString();
    } else {
      memo[key] = val;
    }

    return memo;
  }, {} as any);
};

/**
 * Fetch the swap transaction status and return an updated transaction object.
 * @param tx - Swap transaction object from transaction store
 * @param publicClient - Public client instance for the chain
 * @param options - Options object
 * @returns
 */
const updateSwapTransactionStatus = async (
  tx: SwapTransaction,
  publicClient: PublicClient<Transport, Chain>,
  options?: {
    smartContractWalletAddress?: EthereumAddress;
  },
) => {
  if (!isEthereumHash(tx.hash)) return null;

  const { logs, logsBloom, ...receipt } = await publicClient.getTransactionReceipt({
    hash: tx.hash,
  });

  // if there is no transaction receipt, the transaction has yet to be included in a block
  if (!receipt) return null;

  const serializableReceiptDataToStore = serializeReceiptData(receipt);

  // try to parse the swap using the 0x parser then return the transaction with the parsed data
  // otherwise return the transaction with the receipt data
  try {
    const { transactionHash } = receipt;
    const parsedTx = await parseSwap({
      publicClient,
      transactionHash,
      smartContractWallet: options?.smartContractWalletAddress,
    });

    return {
      ...tx,
      raw: {
        ...(tx.raw || {}),
        ...serializableReceiptDataToStore,
      },
      parsedTx,
    };
  } catch (err) {
    if (err instanceof ApiError || err instanceof Error) {
      trackError(err);
    }

    return {
      ...tx,
      raw: {
        ...(tx.raw || {}),
        ...serializableReceiptDataToStore,
      },
    };
  }
};

/**
 * Fetch the wrap transaction status and return an updated transaction object.
 * @param tx - Wrap transaction object from transaction store
 * @param publicClient - Public client instance for the chain
 * @returns
 */
const updateWrapTransactionStatus = async (
  tx: WrapTransaction,
  publicClient: PublicClient<Transport, Chain>,
) => {
  if (!isEthereumHash(tx.hash)) return null;

  const { logs, logsBloom, ...receipt } = await publicClient.getTransactionReceipt({
    hash: tx.hash,
  });

  // if there is no transaction receipt, the transaction has yet to be mined
  if (!receipt) return null;

  const serializableReceiptDataToStore = serializeReceiptData(receipt);

  return {
    ...tx,
    raw: {
      ...(tx.raw || {}),
      ...serializableReceiptDataToStore,
    },
  };
};

/**
 * Fetch the gasless transaction status and return an updated transaction object.
 * @param tx - Gasless transaction object from transaction store
 * @param publicClient - Public client instance for the chain
 * @returns
 */
const updateGaslessTransactionStatus = async (
  tx: GaslessTransaction,
  publicClient: PublicClient<Transport, Chain>,
) => {
  const params: GaslessStatusRequest = {
    chainId: publicClient.chain.id,
    tradeHash: tx.hash,
  };

  const response = await fetch(`${routes.api.GASLESS_STATUS}${objectToQueryString(params)}`);

  if (!response.ok) {
    if (tx.transactionType === 'gasless') throw createApiError(await response.json());
    throw new ClientNetworkError("Couldn't fetch gasless status");
  }

  const gaslessStatusResponse: GaslessStatusResponse = await response.json();

  // if the gasless transaction has succeeded, parse the swap then return the transaction with the parsed data
  // otherwise return the transaction with the gasless status response
  if (gaslessStatusResponse.status === 'succeeded') {
    const [transaction] = gaslessStatusResponse.transactions;
    const { hash: transactionHash } = transaction;

    try {
      const parsedTx = await parseSwap({
        publicClient,
        transactionHash,
      });

      if (!parsedTx || parsedTx.tokenIn.amount === '') {
        throw new ClientDataError(
          `Failed to parse swap transaction ${transactionHash} on chain ID ${publicClient.chain.id}`,
        );
      }

      return {
        ...tx,
        raw: { ...(tx.raw || {}), ...gaslessStatusResponse },
        parsedTx,
      };
    } catch (err) {
      if (err instanceof ApiError || err instanceof Error) {
        trackError(err);
      }
    }
  }

  return { ...tx, raw: { ...(tx.raw || {}), ...gaslessStatusResponse } };
};

/**
 * Transaction Updater component
 *
 * Singleton to be placed at the app level.
 * This component listens to block changes and updates transactions in the transaction store.
 *
 * @returns null
 */
const TransactionUpdater = () => {
  const { ethereumWallet } = useMatchaWallets();
  const chain = getConnectedChain(ethereumWallet);
  const { referrerId } = useReferrerStore(referrerIdSelector);
  const publicClient = usePublicClient({ chainId: chain?.id });
  const isSmartWallet = useIsSmartWallet({ chainId: chain?.id });
  const completeTransactions = useTransactionStore(completeTransactionsSelector);
  const transactions = useTransactionStore(transactionsSelector);
  const { setRecentTransaction } = useTransactionStore(recentTransactionSelector);
  const [loading, setLoading] = useState<TransactionLoading[]>([]);
  const { mutate: mutateScrollProgress, data: scrollBadgeProgress } = useScrollBadgeProgress({
    address: ethereumWallet?.address,
  });

  const pendingTransactions = useMemo(() => {
    if (!ethereumWallet || !chain) return [];

    return (
      transactions[ethereumWallet.address]?.[chain.id]?.filter((t: Transaction) =>
        t.hash ? isTransactionPending(t) : false,
      ) || []
    );
  }, [ethereumWallet, chain, transactions]);

  const { data: blockNumber } = useBlockNumber({
    chainId: chain?.id,
    watch: pendingTransactions.length > 0,
  });

  const previousBlockNumber = usePrevious(blockNumber);

  /**
   * This function fetches confirmed transactions (success and/or reverted) from a list of pending transactions
   *
   * @remarks
   *
   * For swaps and wraps, fethc a transaction receipt. if undefined, the transaciton is still pending.
   * For gasless transactions, the status is fetched and updated.
   *
   * @param pendingTransactions - pending transactions to process
   * @param chainId - chain id
   * @param lastBlockNumber - last block number
   * @returns  - returns an array of finished transactions
   */
  const getConfirmedTransactions = useCallback(
    async (
      pendingTransactions: Transaction[],
      publicClient: PublicClient<Transport, Chain>,
      lastBlockNumber: bigint,
    ): Promise<Transaction[]> => {
      setLoading((loading) =>
        uniqWith(
          [
            ...pendingTransactions.map((tx: Transaction) => ({
              hash: tx.hash,
              lastBlockNumber,
            })),
            ...loading,
          ],
          (lA, lB) => lA.hash === lB.hash,
        ),
      );

      try {
        // Wait for transaction receipt requests to settle. (Success and/or errors)
        const allSettled = await Promise.allSettled(
          pendingTransactions.map(async (tx: Transaction) => {
            if (!publicClient) return tx;

            switch (tx.transactionType) {
              case 'swap':
                return updateSwapTransactionStatus(tx, publicClient, {
                  smartContractWalletAddress: isSmartWallet
                    ? (ethereumWallet?.address as EthereumAddress)
                    : undefined,
                });
              case 'wrap':
                return updateWrapTransactionStatus(tx, publicClient);
              case 'gasless':
                return updateGaslessTransactionStatus(tx, publicClient);
              default:
                return tx;
            }
          }),
        );

        // Filter out null values and errors
        const confirmedTransactions: Transaction[] = allSettled.reduce((arr, settled) => {
          if (settled.status === 'fulfilled') {
            if (!settled.value) return arr; // skip null values
            arr.push(settled.value);
          } else {
            // settled.status === 'rejected' has an error message: settled.reason
            // if (settled.status === 'rejected') {
            //   trackError(new ClientTransactionError(settled.reason));
            // }
          }
          return arr;
        }, [] as Transaction[]);

        // filter out transactions that are finished
        setLoading((loading) =>
          loading.filter(
            ({ hash }) =>
              !confirmedTransactions.find((tx: Transaction) => {
                return tx.hash === hash;
              }),
          ),
        );

        return confirmedTransactions;
      } catch (err) {
        console.debug('process completed transaction error', err);

        if (err instanceof ApiError || err instanceof Error) trackError(err);

        return [];
      }
    },
    [ethereumWallet, isSmartWallet],
  );

  // On block changes, update the transaction store when pending transactions are confirmed.
  // This effect is intended to continue fetching transaction statuses until all pending transactions are resolved.
  useEffect(() => {
    if (!ethereumWallet || !chain || !blockNumber || !previousBlockNumber || !publicClient) return;
    if (previousBlockNumber === blockNumber) return; // skip if block number hasn't changed

    const dedupedPendingTransactions = pendingTransactions.filter((tx) => {
      const find = loading.find(({ hash }) => hash === tx.hash);
      return find ? find.lastBlockNumber < blockNumber : true;
    }); // filter out pending tx's already loading or loaded in the same block

    if (!dedupedPendingTransactions.length) return;

    // fetch the transaction statuses and then update the transaction store with new data
    getConfirmedTransactions(dedupedPendingTransactions, publicClient, blockNumber).then(
      (confirmedTransactions: Transaction[]) => {
        if (confirmedTransactions.length) {
          setRecentTransaction(true);
        }

        // update the transaction store with the new completed transaction data
        // completed transactions can be either successful or failed
        completeTransactions(confirmedTransactions, ethereumWallet.address, chain.id);

        // do more stuff with the completed transactions
        confirmedTransactions.forEach((transaction) => {
          if (isTransactionSuccessful(transaction)) {
            const hash = hasTransactions(transaction.raw)
              ? transaction.raw.transactions[0].hash
              : transaction.hash;

            if (
              transaction.buyToken.chainId === CHAIN_IDS.SCROLL &&
              ['swap', 'wrap', 'gasless'].includes(transaction.transactionType)
            ) {
              /** Tracking scroll trades for the matcha scroll badge */
              incrementScrollProgress({
                address: ethereumWallet.address,
                isGasless: transaction.transactionType === 'gasless',
                hash,
                timestamp: Date.now(),
              });
              if (scrollBadgeProgress) {
                // optimistic update
                mutateScrollProgress(
                  {
                    requiredTrades: scrollBadgeProgress.requiredTrades,
                    numTrades: scrollBadgeProgress.numTrades + 1,
                  },
                  {
                    revalidate: false,
                  },
                );
              }
              /** End scroll badge monitoring */
            }

            // Log each completed transaction as a `TRANSACTION_COMPLETED` event with it's own properties only once.
            logTxnModuleEvent(
              EVENT_NAME.TRANSACTION_COMPLETED,
              transaction?.sellToken,
              transaction?.buyToken,
              {
                txHash: hash,
                sellAmountUsd: transaction?.sellAmountUsd,
                orderFlowType: transaction.transactionType === 'gasless' ? 'auto' : 'standard',
                matchaReferrer: referrerId,
              },
            );
          }
        });
      },
    );
  }, [
    ethereumWallet,
    blockNumber,
    chain,
    completeTransactions,
    pendingTransactions,
    loading,
    previousBlockNumber,
    getConfirmedTransactions,
    publicClient,
    scrollBadgeProgress,
    mutateScrollProgress,
    referrerId,
    setRecentTransaction,
  ]);

  return null;
};

function hasTransactions(
  raw: SerializableTransactionReceipt | GaslessSubmitResponse | GaslessStatusResponse | undefined,
): raw is GaslessStatusResponse {
  return !!raw && 'transactions' in raw;
}

export default TransactionUpdater;
