import { BaseConnectedWalletType } from '@privy-io/react-auth';
import { Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Decimal } from 'decimal.js-light';

import { JSON_RPC_URL_SOLANA_HTTP_JWT } from '@/constants/rpc';

import { getNodeProviderJwt } from '@/hooks/useNodeProviderToken';

let sharedConnection: Connection;

export const getDisconnectedKey = (wallet: BaseConnectedWalletType) => {
  return `solana.${wallet.meta.name.toLowerCase()}.disconnected`;
};

export async function getSolanaConnection() {
  if (sharedConnection) return sharedConnection;
  sharedConnection = new Connection(JSON_RPC_URL_SOLANA_HTTP_JWT, {
    fetchMiddleware: async (rpcUrl, init, fetchFn) => {
      if (init) {
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${getNodeProviderJwt()}`,
        };
        fetchFn(rpcUrl, init);
      }
    },
  });
  return sharedConnection;
}

let sharedJitoTxConnection: Connection;

export async function getSolanaJitoTxConnection() {
  if (sharedJitoTxConnection) return sharedJitoTxConnection;
  sharedJitoTxConnection = new Connection(
    'https://mainnet.block-engine.jito.wtf/api/v1/transactions',
  );
  return sharedJitoTxConnection;
}

export function lamportToSol(lamport: number | string): string {
  return new Decimal(lamport).div(LAMPORTS_PER_SOL).toString();
}
