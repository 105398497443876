import * as Sentry from '@sentry/nextjs';
import { PublicKey } from '@solana/web3.js';
import { useCallback, useEffect, useRef } from 'react';
import useSwr from 'swr';
import { formatUnits } from 'viem';
import { useAccountEffect, usePublicClient } from 'wagmi';

import { NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID } from '@/constants/addresses';

import { useMatchaWallets } from '@/hooks/useMatchaWallets';
import useTokenResult from '@/hooks/useTokenResult';

import { getSolanaConnection } from '@/utils/solana';

import { NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID } from '../../../constants/chain';
import { REFRESH_INTERVAL } from '../../../constants/rpc';
import { useBalanceStore } from '../../../store/balance';
import {
  EVENT_NAME,
  IDENTIFY_TYPES,
  USER_PROPERTIES,
  identifyUserProperties,
  logEvent,
} from '../../../utils/amplitude';
import { trackError } from '../../../utils/errors';
import { EthereumAddress } from '../../../utils/models';
import { getConnectedChain } from '../../../utils/wallet';

interface Props {
  onSetCanTrade: (canTrade: boolean) => void;
}

const AppUpdater = ({ onSetCanTrade }: Props) => {
  const { solanaWallet, ethereumWallet } = useMatchaWallets();
  const chain = getConnectedChain(ethereumWallet);
  const nativeTokenInfo = useTokenResult(
    NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[chain?.id ?? 1],
    chain?.id,
  );
  const publicClient = usePublicClient({ chainId: chain?.id });
  const setNativeTokenBalance = useBalanceStore((store) => store.setNativeTokenBalance);
  const hasIdentifiedThisSession = useRef(false);

  // fetch and set sol balance on wallet change
  useEffect(() => {
    if (!ethereumWallet && !solanaWallet) {
      setNativeTokenBalance(undefined, 'sol');
      setNativeTokenBalance(undefined, 'evm');
      return;
    }
    const solUpdater = async () => {
      const connection = await getSolanaConnection();
      const balance = await connection.getBalance(
        new PublicKey(solanaWallet!.address),
        'confirmed',
      );
      setNativeTokenBalance(formatUnits(BigInt(balance), 9), 'sol');
    };

    const evmUpdater = async () => {
      if (!publicClient || !ethereumWallet) return;
      const balance = await publicClient.getBalance({
        address: ethereumWallet.address as EthereumAddress,
      });
      setNativeTokenBalance(formatUnits(balance, 18), 'evm');
    };

    if (solanaWallet) {
      solUpdater();
    } else {
      setNativeTokenBalance(undefined, 'sol');
    }
    if (ethereumWallet) {
      evmUpdater();
    } else {
      setNativeTokenBalance(undefined, 'evm');
    }
  }, [setNativeTokenBalance, solanaWallet, ethereumWallet, publicClient]);

  const fetchAndSetBalance = useCallback(
    async (address: `0x${string}`) => {
      if (!publicClient) return;
      try {
        const balance = await publicClient.getBalance({ address });
        setNativeTokenBalance(formatUnits(balance, 18), 'evm');
      } catch (err: unknown) {
        console.debug(
          'There was an error fetching the native balance on connecting the wallet',
          err,
        );
        if (err instanceof Error) {
          trackError(err);
        }
      }
    },
    [publicClient, setNativeTokenBalance],
  );

  const handleUserIdentification = useCallback(
    (address: string, connectorName: string, chainId?: number) => {
      if (hasIdentifiedThisSession.current) return;
      // user properties set per session
      identifyUserProperties({
        type: IDENTIFY_TYPES.POST_INSERT,
        key: USER_PROPERTIES.WALLETS_USED,
        value: address,
      });
      identifyUserProperties({
        type: IDENTIFY_TYPES.SET,
        key: USER_PROPERTIES.WALLET_CONNECTED,
        value: connectorName,
      });
      identifyUserProperties({
        type: IDENTIFY_TYPES.SET,
        key: USER_PROPERTIES.ADDRESS_CONNECTED,
        value: address,
      });

      Sentry.setTag('user.account.wallet', connectorName || 'unknown');
      Sentry.setTag('user.account.address', address);

      if (chainId) {
        identifyUserProperties({
          type: IDENTIFY_TYPES.SET,
          key: USER_PROPERTIES.CHAIN_SELECTED,
          value: chainId.toString(),
        });
        identifyUserProperties({
          type: IDENTIFY_TYPES.SET,
          key: USER_PROPERTIES.CHAIN_SELECTED_NAME,
          value: NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[chainId],
        });
        Sentry.setTag('user.account.selected_chain', chainId.toString() ?? 'unknown');
      }
    },
    [],
  );
  if (
    ethereumWallet !== undefined &&
    publicClient !== undefined &&
    !hasIdentifiedThisSession.current
  ) {
    handleUserIdentification(ethereumWallet.address, ethereumWallet.meta.id, chain?.id);
    hasIdentifiedThisSession.current = true;
  }
  useAccountEffect({
    async onConnect({ address, connector }) {
      if (address === undefined || connector === undefined) return;
      logEvent({
        name: EVENT_NAME.WALLET_CONNECTED,
        properties: { address, connectorName: connector.name },
      });
      handleUserIdentification(address, connector.name, chain?.id);
      hasIdentifiedThisSession.current = true;
      fetchAndSetBalance(address);
    },
    onDisconnect() {
      logEvent({
        name: EVENT_NAME.WALLET_DISCONNECTED,
      });
      hasIdentifiedThisSession.current = false;
    },
  });

  const fetchBalance = useCallback(async () => {
    if (!ethereumWallet) return;
    if (!publicClient) return;

    const balance = await publicClient.getBalance({
      address: ethereumWallet.address as EthereumAddress,
    });
    return balance;
  }, [ethereumWallet, publicClient]);

  useSwr(chain?.id ? `fetch-balance-${chain?.id}` : null, fetchBalance, {
    onSuccess: (balance: bigint | undefined) => {
      if (balance)
        setNativeTokenBalance(formatUnits(balance, nativeTokenInfo?.decimals ?? 18), 'evm');
    },
    refreshInterval: REFRESH_INTERVAL,
    refreshWhenHidden: false,
  });

  return null;
};

export default AppUpdater;
