import { addRpcUrlOverrideToChain, PrivyClientConfig, WalletListEntry } from '@privy-io/react-auth';
import { Chain } from 'viem';

import { SUPPORTED_CHAINS } from '@/constants/chain';
import { JWT_PROTECTED_JSON_RPC_HTTP_URL_BY_CHAIN_ID } from '@/constants/rpc';

import { routes } from './routes';

export const PRIVY_ID = process.env.NEXT_PUBLIC_PRIVY_ID as string;

const walletConnectCloudProjectId = process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID;

const needsInjectedWalletFallback =
  typeof window !== 'undefined' &&
  window.ethereum &&
  !window.ethereum.isMetaMask &&
  !window.ethereum.isPhantom &&
  !window.ethereum.isRabby &&
  !window.ethereum.isRainbow &&
  !window.ethereum.isZerion &&
  !window.ethereum.isCoinbaseWallet;

// configure wallet connectors
export const walletList: WalletListEntry[] = [
  // order is display order in UI
  'coinbase_wallet',
  'metamask',
  'phantom',
  'rainbow',
  'rabby_wallet',
  'backpack',
  'zerion',
  'wallet_connect',
  ...(needsInjectedWalletFallback ? ['detected_ethereum_wallets' as WalletListEntry] : []),
];

export const config: PrivyClientConfig = {
  // Customize Privy's appearance in your app
  appearance: {
    theme: 'light',
    accentColor: '#00B96D',
    // logo: 'https://matcha.xyz/apple-touch-icon.png',
    walletChainType: 'ethereum-only',
    // TODO: revisit the `walletList` flag, as we believe this value causes a refresh bug with Privy.
    // See: https://0xproject.slack.com/archives/C07552A6Z7W/p1740084312980349
    // walletList,
  },
  // Create embedded wallets for users who don't have a wallet
  // Uncomment when enabling embedded wallets
  // embeddedWallets: {
  //   createOnLogin: 'users-without-wallets',
  //   requireUserPasswordOnCreate: true,
  //   showWalletUIs: false,
  // },
  /** All legal configuration */
  legal: {
    termsAndConditionsUrl: routes.TERMS,
    privacyPolicyUrl: routes.PRIVACY,
  },
  loginMethods: ['email', 'wallet'],
  supportedChains: SUPPORTED_CHAINS.map((chain: Chain) =>
    addRpcUrlOverrideToChain(chain, JWT_PROTECTED_JSON_RPC_HTTP_URL_BY_CHAIN_ID[chain.id]),
  ),
  walletConnectCloudProjectId,
};

/** Convert EVM Caip-2 chainId format to number
 *
 * @remarks
 *
 * This function is used to extract the chainId number from the privy chainId format "eip155:1285"
 * https://docs.privy.io/reference/sdk/server-auth/type-aliases/EvmCaip2ChainId
 *
 * @param chainId - privy chainId format
 */
export const getChainIdNumberFromCaip2ChainId = (chainId: string): number =>
  parseInt(chainId.split(':')[1], 10);
