import { ZodError } from 'zod';

import {
  ApiErrorDetails,
  ApiNoLiquidityAvailableError,
  ErrorContext,
  createApiError,
} from '@/lib/errors/api';
import { ClientNetworkError } from '@/lib/errors/client';

import * as environment from './environment';
import { HttpError } from './http-error';

/**
 * List of all the fetcher functions that are used by `useSWR` hooks.
 *
 * @remarks
 * Next.js requires that `fetch` in a test env uses an absolute URL. This is
 * because the application doesn't know what context this code will be called in
 * (either in the browser on production or in node when running tests/SSR), so
 * the implementation of `fetch` can vary between them.
 */

/**
 * Helper function to get the full URL.
 */

export const getFullURL = (path: string) => {
  const baseUrl = environment.isTest ? environment.getBaseURL() : '';
  return baseUrl + path;
};

/**
 * Helper function to handle the response.
 */
export const handleResponse = async <T = any>(r: Response): Promise<T> => {
  if (!r.ok) throw new ClientNetworkError(await r.text());
  return r.json();
};

/**
 * Default fetcher for GET requests.
 */
export const fetcher = async (path: string) => fetch(getFullURL(path)).then(handleResponse);

/**
 * Default fetcher for POST requests.
 */
export const postData = async (path: string, { arg }: { arg: unknown }) =>
  fetch(getFullURL(path), {
    method: 'POST',
    body: JSON.stringify(arg),
    headers: { 'Content-Type': 'application/json' },
  }).then(handleResponse);

/**
 * Fetcher for GET requests to 0x API.
 *
 * @remarks
 * This fetcher is used to handle errors from the 0x API. It first checks for a
 * Zod error, then an API error, and finally throws an `Error`.
 */
export const zeroExApiFetcher = async (path: string, context?: ErrorContext) => {
  const response = await fetch(getFullURL(path));
  if (response.status === 204) {
    throw new ApiNoLiquidityAvailableError();
  }
  const data = await response.json();

  if (!response.ok) {
    if (data.code === 400) {
      if ('issues' in data) {
        throw new ZodError(data.issues);
      } else if ('reason' in data) {
        throw new HttpError(400, data, context);
      }
    } else if ('data' in data && 'name' in data && 'message' in data) {
      throw createApiError(data as ApiErrorDetails, context);
    }
    throw new Error(data.message);
  }

  return data;
};
