const ENV = {
  BREEZE_API_KEY: process.env.BREEZE_API_KEY,
  DEFINED_API_KEY: process.env.DEFINED_API_KEY,
  SOCKET_API_KEY: process.env.SOCKET_API_KEY,
  TRANSACTIONS_API_KEY: process.env.TRANSACTIONS_API_KEY,
  ZEROEX_API_KEY: process.env.ZEROEX_API_KEY,
  SIGNING_SECRET: process.env.SIGNING_SECRET,
  TRADE_BROADCASTER_URL: process.env.TRADE_BROADCASTER_URL,
  BROADCASTER_SECRET: process.env.BROADCASTER_SECRET,
  MATCHA_BLOG_BEARER_TOKEN: process.env.MATCHA_BLOG_BEARER_TOKEN,
  MATCHA_BLOG_COLLECTION_ID: process.env.MATCHA_BLOG_COLLECTION_ID,
  SPINDL_API_KEY: process.env.SPINDL_API_KEY,
  TOKEN_CONTENT_API_KEY: process.env.TOKEN_CONTENT_API_KEY,
  SCROLL_QUICKNODE_URL: process.env.NEXT_PUBLIC_QUICKNODE_RPC_URL_SCROLL,
  ZEROEX_API_BASE_URL: process.env.ZEROEX_API_BASE_URL,
  NODE_PROVIDER_KID: process.env.NODE_PROVIDER_KID,
  RPC_NODE_PROVIDER_PUBLIC_KEY: process.env.RPC_NODE_PROVIDER_PUBLIC_KEY,
  RPC_NODE_PROVIDER_PRIVATE_KEY: process.env.RPC_NODE_PROVIDER_PRIVATE_KEY,
  ETHERSCAN_API_URL: process.env.ETHERSCAN_API_URL,
  ETHERSCAN_API_KEY: process.env.ETHERSCAN_API_KEY,
  REDIS_URL: process.env.REDIS_URL,
  SOLSCAN_API_KEY: process.env.SOLSCAN_API_KEY,
};

export default ENV;
