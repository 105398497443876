import type { GaslessStatusResponse } from '../../utils/gasless/types';
import {
  SerializableTransactionReceipt,
  SwapTransaction,
  Transaction,
  GaslessTransaction,
  WrapTransaction,
} from './types';

const isValidTx = (
  transaction: Transaction,
  completed: Transaction,
  txType: 'swap' | 'wrap' | 'gasless',
) =>
  transaction.transactionType === txType &&
  completed.transactionType === txType &&
  !!completed.hash &&
  transaction.hash === completed.hash;

const isSwapTransactionCompletedSuccessfully = (
  transaction: Transaction,
  completed: Transaction,
) => {
  if (!isValidTx(transaction, completed, 'swap')) return false;
  const status = (completed.raw as SerializableTransactionReceipt)?.status;

  return status === 'success';
};

const isWrapTransactionCompletedSuccessfully = (
  transaction: Transaction,
  completed: Transaction,
) => {
  if (!isValidTx(transaction, completed, 'wrap')) return false;
  const status = (completed.raw as SerializableTransactionReceipt)?.status;

  return status === 'success';
};

const isGaslessTransactionCompletedSuccessfully = (
  transaction: Transaction,
  completed: Transaction,
) => {
  if (!isValidTx(transaction, completed, 'gasless')) return false;
  const status = (completed.raw as GaslessStatusResponse)?.status;
  return status === 'succeeded' || status === 'confirmed';
};

export const isTransactionCompletedSuccessfully = (
  transaction: Transaction,
  completed: Transaction,
) =>
  isWrapTransactionCompletedSuccessfully(transaction, completed) ||
  isSwapTransactionCompletedSuccessfully(transaction, completed) ||
  isGaslessTransactionCompletedSuccessfully(transaction, completed);

const isSwapTransactionCompletedWithFailure = (
  transaction: Transaction,
  completed: Transaction,
) => {
  if (!isValidTx(transaction, completed, 'swap')) return false;
  const status = (completed.raw as SerializableTransactionReceipt)?.status;
  return status === 'reverted';
};

const isWrapTransactionCompletedWithFailure = (
  transaction: Transaction,
  completed: Transaction,
) => {
  if (!isValidTx(transaction, completed, 'wrap')) return false;
  const status = (completed.raw as SerializableTransactionReceipt)?.status;
  return status === 'reverted';
};

const isGaslessTransactionCompletedWithFailure = (
  transaction: Transaction,
  completed: Transaction,
) => {
  if (!isValidTx(transaction, completed, 'gasless')) return false;
  const status = (completed.raw as GaslessStatusResponse)?.status;
  return status === 'failed';
};

export const isTransactionCompletedWithFailure = (
  transaction: Transaction,
  completed: Transaction,
) =>
  isSwapTransactionCompletedWithFailure(transaction, completed) ||
  isWrapTransactionCompletedWithFailure(transaction, completed) ||
  isGaslessTransactionCompletedWithFailure(transaction, completed);

const isSwapTransactionPending = (transaction: Transaction): boolean =>
  !!(transaction.transactionType === 'swap' && !(transaction.raw && 'status' in transaction.raw));

const isWrapTransactionPending = (transaction: Transaction): boolean =>
  !!(transaction.transactionType === 'wrap' && !(transaction.raw && 'status' in transaction.raw));

const isGaslessTransactionPending = (transaction: Transaction): boolean => {
  const status = transaction.raw && (transaction.raw as GaslessStatusResponse)?.status;

  return transaction.transactionType === 'gasless'
    ? !status || status === 'pending' || status === 'submitted'
    : false;
};

export const isTransactionPending = (transaction: Transaction) =>
  isWrapTransactionPending(transaction) ||
  isSwapTransactionPending(transaction) ||
  isGaslessTransactionPending(transaction);

const isGaslessTransactionSuccessful = (transaction: GaslessTransaction): boolean => {
  const status = transaction.raw && (transaction.raw as GaslessStatusResponse)?.status;
  return status === 'confirmed' || status === 'succeeded';
};

const isSwapOrWrapTransactionSuccessful = (
  transaction: SwapTransaction | WrapTransaction,
): boolean => {
  const status = transaction.raw && (transaction.raw as SerializableTransactionReceipt)?.status;
  return status === 'success';
};

export const isTransactionSuccessful = (transaction: Transaction): boolean => {
  const { transactionType } = transaction;
  if (transactionType === 'swap' || transactionType === 'wrap')
    return isSwapOrWrapTransactionSuccessful(transaction);
  if (transactionType === 'gasless') return isGaslessTransactionSuccessful(transaction);
  return false;
};
