import { cloneDeep } from 'lodash';
import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { trackError } from '../../utils/errors';
import { syncTabs } from '../middleware/sync-tabs';
import { CurrentTransactionInfo, Transaction, TransactionsByAccount } from './types';
// utils
import {
  isTransactionCompletedSuccessfully,
  isTransactionCompletedWithFailure,
  isTransactionPending,
} from './utils';

const DEFAULT_TRANSACTIONS: Transaction[] = [];

interface TransactionStore {
  recentTransaction: boolean;
  setRecentTransaction: (recentTransaction: boolean) => void;
  currentTransaction: CurrentTransactionInfo | null;
  resetCurrentTransaction: () => void;
  transactions: TransactionsByAccount;
  addTransaction: (tx: Transaction, address: string, chainId: number) => void;
  completeTransactions: (receipt: Transaction[], address: string, chainId: number) => void;
  getPendingTransactions: (address: string, chainId: number) => Transaction[];
}

let isRehydrating = false;

export function pruneTransactions(transactions: TransactionsByAccount) {
  const now = Date.now();
  const cutoff = now - 30 * 60 * 1000; // 30 minutes

  const cloned = cloneDeep(transactions) || {};

  for (const account in cloned) {
    for (const chainId in cloned[account]) {
      const nChainId = Number(chainId);

      if (Number.isNaN(nChainId)) {
        // this just assists to help typescript out
        continue;
      }
      //  I hate all this yelling at typescript but it's a necessary evil, it is unable to infer the type correctly
      cloned![account]![nChainId] = cloned![account]![nChainId]!.filter(
        (tx) => new Date(tx.timestamp).getTime() > cutoff,
      );
      if (cloned![account]![nChainId]!.length === 0) {
        delete cloned![account]![nChainId];
      }
    }
    if (Object.keys(cloned![account] || {}).length === 0) {
      delete cloned![account];
    }
  }
  return cloned || {};
}

function logStorageSizes() {
  const sizes = {} as Record<string, number>;
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) continue;
    sizes[key] = localStorage.getItem(key)?.length ?? 0;
  }
  console.log(JSON.stringify(sizes, null, 2));
}

function customStorage() {
  const localStorage =
    typeof window !== 'undefined'
      ? window.localStorage
      : {
          getItem: () => null,
          setItem: () => null,
          removeItem: () => null,
        };
  if (typeof window === 'undefined') {
    console.warn(
      'localStorage is not available in this environment, transactions will not be persisted',
    );
  }
  return {
    getItem(key: string) {
      try {
        const item = localStorage.getItem(key);
        if (!item) return null;
        return item;
      } catch (error) {
        trackError(error as Error);
        return null;
      }
    },
    setItem(key: string, value: string, tryCount = 0) {
      try {
        const parsed = JSON.parse(value) as { state: TransactionStore; version: number };
        // if we are on the first try, prune transactions
        if (tryCount === 0 && parsed.state.transactions) {
          parsed.state.transactions = pruneTransactions(parsed.state.transactions);
        }
        // if we are on a higher try, remove the oldest transaction
        if (tryCount > 0) {
          let oldestAccount = '';
          let oldestChainId = 0;
          let oldestTimestamp = Date.now();
          for (const account in parsed.state.transactions) {
            for (const chainId in parsed.state.transactions[account]!) {
              const txEpochTime = new Date(
                parsed.state.transactions[account]![chainId]![0]?.timestamp,
              ).getTime();
              if (txEpochTime <= oldestTimestamp) {
                oldestAccount = account;
                oldestChainId = Number(chainId);
                oldestTimestamp = txEpochTime;
              }
            }
          }
          if (oldestAccount !== '' && oldestChainId !== 0) {
            let currentTransactions = parsed.state.transactions[oldestAccount]![oldestChainId]!;
            currentTransactions = currentTransactions.slice(tryCount);

            parsed.state.transactions[oldestAccount]![oldestChainId] = currentTransactions;
            if (Object.keys(parsed.state.transactions[oldestAccount]!).length === 0) {
              delete parsed.state.transactions[oldestAccount];
            }
          }
        }
        // remove all keys with values that are undefined
        for (const [key, value] of Object.entries(parsed.state)) {
          if (value === undefined) {
            delete parsed.state[key as keyof TransactionStore];
          }
        }
        localStorage.setItem(key, JSON.stringify(parsed));
        // send storage event
        if (!isRehydrating) {
          window.dispatchEvent(new Event('transaction-store-updated'));
        }
      } catch (error) {
        console.error('Error setting item in local storage', error);
        logStorageSizes();
        if (tryCount < 3) {
          this.setItem(key, value, tryCount + 1);
        } else {
          trackError(error as Error);
        }
      }
    },
    removeItem(key: string) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        trackError(error as Error);
      }
    },
  };
}

export const useTransactionStore = createWithEqualityFn(
  persist<TransactionStore>(
    syncTabs(
      (set, get) => ({
        recentTransaction: false,
        setRecentTransaction: (recentTransaction: boolean) => {
          set({ recentTransaction });
        },
        currentTransaction: null,
        resetCurrentTransaction() {
          set({ currentTransaction: null });
        },
        transactions: {},
        addTransaction(tx: Transaction, address: string, chainId: number) {
          const transactions = get().transactions;
          const newCurrentTransaction = {
            tx,
            address,
            chainId,
            isSuccessful: false,
            isFailed: false,
          };

          const newTransactions = {
            ...(transactions || {}),
            [address]: {
              ...(transactions?.[address] || {}),
              [chainId]: [...(transactions?.[address]?.[chainId] || []), { ...tx }],
            },
          };

          set({
            currentTransaction: newCurrentTransaction,
            transactions: pruneTransactions(newTransactions),
          });
        },
        completeTransactions(receipts: Transaction[], address: string, chainId: number) {
          const transactions = get().transactions;
          const newTransactions = {
            ...(transactions || {}),
            [address]: {
              ...(transactions?.[address] || {}),
              [chainId]: (transactions?.[address]?.[chainId] || []).map(
                (transaction: Transaction): Transaction => {
                  // Find the receipt that matches the current transaction
                  const receipt = receipts.find(
                    (receipt) =>
                      isTransactionCompletedSuccessfully(transaction, receipt) ||
                      isTransactionCompletedWithFailure(transaction, receipt),
                  );
                  // If no receipt was found, return the transaction as is
                  if (!receipt) return transaction;
                  // Update the transaction with the receipt
                  return {
                    ...transaction,
                    ...receipt,
                    transactionType: transaction.transactionType,
                  } as Transaction;
                },
              ),
            },
          };

          // Update the current transaction if it matches one of the receipts
          const currentTransaction = get().currentTransaction;
          let newCurrentTransaction = currentTransaction ? { ...currentTransaction } : null;
          if (newCurrentTransaction) {
            const successfulReceipt = receipts.find((receipt) =>
              isTransactionCompletedSuccessfully(newCurrentTransaction!.tx, receipt),
            );
            newCurrentTransaction.isSuccessful = !!successfulReceipt;
            const failedReceipt = receipts.find((receipt) =>
              isTransactionCompletedWithFailure(newCurrentTransaction!.tx, receipt),
            );
            newCurrentTransaction.isFailed = !!failedReceipt;
          }

          set({
            currentTransaction: newCurrentTransaction,
            transactions: pruneTransactions(newTransactions),
          });
        },
        getPendingTransactions(address: string, chainId: number): Transaction[] {
          if (!address || !chainId) return DEFAULT_TRANSACTIONS;

          const transactions = get().transactions[address]?.[chainId];

          if (!transactions) return DEFAULT_TRANSACTIONS;

          return transactions.filter((transaction: Transaction) =>
            transaction.hash ? isTransactionPending(transaction) : false,
          );
        },
      }),
      {
        channelName: 'matcha-transactions',
        partialize: (state) => ({ transactions: state.transactions }),
      },
    ),
    {
      name: 'matcha-transactions',
      storage: createJSONStorage(customStorage),
      onRehydrateStorage: (state) => {
        isRehydrating = true;
        // keep transactions from the last 30 minutes
        const transactions = state.transactions;

        state.transactions = pruneTransactions(transactions);
        return () => {
          isRehydrating = false;
        };
      },
    },
  ),
  shallow,
);

function injectEventHandler(store: typeof useTransactionStore) {
  if (typeof window === 'undefined') return () => {};
  const storageEventCallback = (e: Event) => {
    store.persist.rehydrate();
  };
  window.addEventListener('transaction-store-updated', storageEventCallback);

  return () => {
    window.removeEventListener('transaction-store-updated', storageEventCallback);
  };
}

injectEventHandler(useTransactionStore);
// selectors
export const resetCurrentTransactionSelector = (state: TransactionStore) =>
  state.resetCurrentTransaction;
export const addTransactionSelector = (state: TransactionStore) => state.addTransaction;
export const pendingTransactionSelectors = (state: TransactionStore) => ({
  completeTransactions: state.completeTransactions,
  getPendingTransactions: state.getPendingTransactions,
});
export const transactionsSelectors = ({
  currentTransaction,
  transactions,
  getPendingTransactions,
}: TransactionStore) => ({
  currentTransaction,
  transactions,
  getPendingTransactions,
});
export const transactionsSelector = (state: TransactionStore) => state.transactions;
export const completeTransactionsSelector = (state: TransactionStore) => state.completeTransactions;
export const recentTransactionSelector = (state: TransactionStore) => ({
  recentTransaction: state.recentTransaction,
  setRecentTransaction: state.setRecentTransaction,
});
