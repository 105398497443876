import ExternalLink from '@/ui/Link/ExternalLink';

// utils
import { routes } from '@/utils/routes';

import ByZeroEx from './ByZeroEx';

type Variant = 'gray30' | 'gray40' | 'black06';

const ByZeroExPill = ({ className, variant }: { className?: string; variant: Variant }) => {
  return (
    <ExternalLink className={className} href={routes.ZEROEX_HOME}>
      <ByZeroEx />
    </ExternalLink>
  );
};

export default ByZeroExPill;
