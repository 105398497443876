import type { HttpError } from 'http-errors';

/**
 * API Errors reference: https://0x.org/docs/api
 */

/**
 * Details of an error returned from the 0x API.
 */
export interface ApiErrorDetails {
  /** Unique name/key of the error */
  name: string;
  /** What went wrong */
  message: string;
  /** Data associated with the error */
  data: Record<string, unknown>;
}

/**
 * Context of an error returned from the 0x API. This will include event data
 * used for error reporting purposes.
 */
export type ErrorContext = Record<string, unknown>;

/**
 * Parent class to handle API errors with details.
 */
export class ApiError implements Omit<HttpError, 'expose'> {
  public context?: ErrorContext;
  public details: ApiErrorDetails;
  public message: string;
  public name: string = 'ApiError';
  public status: number;
  public statusCode: number;

  constructor(status: number, details: ApiErrorDetails, context?: ErrorContext) {
    this.details = details;
    this.message = details.message;
    this.status = status;
    this.statusCode = status;

    // optional context
    if (context) this.context = context;
  }
}

/**
 * `BUY_TOKEN_NOT_AUTHORIZED_FOR_TRADE` errors.
 */
export class ApiBuyTokenUnauthorizedForTradeError extends ApiError {
  public name = 'ApiBuyTokenUnauthorizedForTradeError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(422, details, context);
  }
}

/**
 * `INPUT_INVALID` errors.
 */
export class ApiInputInvalidError extends ApiError {
  public name = 'ApiInputInvalidError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `INSUFFICIENT_BALANCE` errors.
 */
export class ApiInsufficientBalanceError extends ApiError {
  public name = 'ApiInsufficientBalanceError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `INSUFFICIENT_BALANCE_OR_ALLOWANCE` errors.
 */
export class ApiInsufficientBalanceOrAllowanceError extends ApiError {
  public name = 'ApiInsufficientBalanceOrAllowanceError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `INTERNAL_SERVER_ERROR` errors.
 */
export class ApiInternalServerError extends ApiError {
  public name = 'ApiInternalServerError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(500, details, context);
  }
}

/**
 * `INVALID_SIGNATURE` errors.
 */
export class ApiInvalidSignatureError extends ApiError {
  public name = 'ApiInvalidSignatureError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `INVALID_SIGNER` errors.
 */
export class ApiInvalidSignerError extends ApiError {
  public name = 'ApiInvalidSignerError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `META_TRANSACTION_EXPIRY_TOO_SOON` errors.
 */
export class ApiMetaTransactionExpiryTooSoonError extends ApiError {
  public name = 'ApiMetaTransactionExpiryTooSoonError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `META_TRANSACTION_INVALID` errors.
 */
export class ApiMetaTransactionInvalidError extends ApiError {
  public name = 'ApiMetaTransactionInvalidError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `META_TRANSACTION_STATUS_NOT_FOUND` errors.
 */
export class ApiMetaTransactionStatusNotFoundError extends ApiError {
  public name = 'ApiMetaTransactionStatusNotFoundError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(404, details, context);
  }
}

/**
 * `PENDING_TRADES_ALREADY_EXIST` errors.
 */
export class ApiPendingTradesAlreadyExistError extends ApiError {
  public name = 'ApiPendingTradesAlreadyExistError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `SELL_AMOUNT_TOO_SMALL` errors.
 */
export class ApiSellAmountTooSmallError extends ApiError {
  public name = 'ApiSellAmountTooSmallError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `SELL_TOKEN_NOT_AUTHORIZED_FOR_TRADE` errors.
 */
export class ApiSellTokenUnauthorizedForTradeError extends ApiError {
  public name = 'ApiSellTokenUnauthorizedForTradeError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(422, details, context);
  }
}

/**
 * `SWAP_VALIDATION_FAILED` errors.
 */
export class ApiSwapValidationFailedError extends ApiError {
  public name = 'ApiSwapValidationFailedError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `TAKER_NOT_AUTHORIZED_FOR_TRADE` errors.
 */
export class ApiTakerNotAuthorizedForTrade extends ApiError {
  public name = 'ApiTakerNotAuthorizedForTrade';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(403, details, context);
  }
}

/**
 * `TOKEN_NOT_SUPPORTED` errors.
 */
export class ApiTokenNotSupportedError extends ApiError {
  public name = 'ApiTokenNotSupportedError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(400, details, context);
  }
}

/**
 * `UNCATEGORIZED` errors.
 */
export class ApiUncategorizedError extends ApiError {
  public name = 'ApiUncategorizedError';

  constructor(details: ApiErrorDetails, context?: ErrorContext) {
    super(500, details, context);
  }
}

/**
 * No liquidity available error
 */
export class ApiNoLiquidityAvailableError extends ApiError {
  public name = 'ApiNoLiquidityAvailableError';

  constructor() {
    super(
      204,
      { name: 'NO_LIQUIDITY_AVAILABLE', message: 'No liquidity available', data: {} },
      undefined,
    );
  }
}

/**
 * Type for any constructor of a class that extends ApiError.
 */
type ApiErrorConstructor<T extends ApiError> = new (
  details: ApiErrorDetails,
  context?: ErrorContext,
) => T;

/**
 * Map of error codes to error constructors.
 */
const codeToError: Record<string, ApiErrorConstructor<ApiError>> = {
  BUY_TOKEN_NOT_AUTHORIZED_FOR_TRADE: ApiBuyTokenUnauthorizedForTradeError,
  INPUT_INVALID: ApiInputInvalidError,
  INSUFFICIENT_BALANCE: ApiInsufficientBalanceError,
  INSUFFICIENT_BALANCE_OR_ALLOWANCE: ApiInsufficientBalanceOrAllowanceError,
  INTERNAL_SERVER_ERROR: ApiInternalServerError,
  INVALID_SIGNATURE: ApiInvalidSignatureError,
  INVALID_SIGNER: ApiInvalidSignerError,
  META_TRANSACTION_EXPIRY_TOO_SOON: ApiMetaTransactionExpiryTooSoonError,
  META_TRANSACTION_INVALID: ApiMetaTransactionInvalidError,
  META_TRANSACTION_STATUS_NOT_FOUND: ApiMetaTransactionStatusNotFoundError,
  PENDING_TRADES_ALREADY_EXIST: ApiPendingTradesAlreadyExistError,
  SELL_AMOUNT_TOO_SMALL: ApiSellAmountTooSmallError,
  SELL_TOKEN_NOT_AUTHORIZED_FOR_TRADE: ApiSellTokenUnauthorizedForTradeError,
  SWAP_VALIDATION_FAILED: ApiSwapValidationFailedError,
  TAKER_NOT_AUTHORIZED_FOR_TRADE: ApiTakerNotAuthorizedForTrade,
  TOKEN_NOT_SUPPORTED: ApiTokenNotSupportedError,
  UNCATEGORIZED: ApiUncategorizedError,
};

/**
 * Create an instance of an API error based on the error key and details.
 * Defaults to uncategorized error if the error key is not recognized.
 */
export const createApiError = (details: ApiErrorDetails, context?: ErrorContext): ApiError =>
  new (codeToError[details.name] ?? ApiUncategorizedError)(details, context);

/**
 * Type guard to check if an error is an instance of `ApiError`.
 */
export const isApiError = (error: unknown): error is ApiError =>
  typeof error === 'object' &&
  error !== null &&
  'details' in error &&
  'name' in error &&
  'status' in error &&
  (error as ApiError).name.startsWith('Api');
