import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

interface BalanceStore {
  nativeTokenBalances: { evm: string | undefined; sol: string | undefined };
  setNativeTokenBalance: (balance: string | undefined, wallet: 'evm' | 'sol') => void;
}

export const useBalanceStore = createWithEqualityFn<BalanceStore>(
  (set, get) => ({
    nativeTokenBalances: { evm: undefined, sol: undefined },
    setNativeTokenBalance: (balance: string | undefined, wallet: 'evm' | 'sol') => {
      const { nativeTokenBalances } = get();
      set({
        nativeTokenBalances: {
          ...nativeTokenBalances,
          [wallet]: balance,
        },
      });
    },
  }),
  shallow,
);

export const nativeTokenBalanceSelectors = ({
  nativeTokenBalances,
  setNativeTokenBalance,
}: BalanceStore) => ({ nativeTokenBalances, setNativeTokenBalance });
