import { decodeJwt } from 'jose';
import { useLocalStorage } from 'react-use';
import useSWR from 'swr';

/**
 * This hook fetches a JWT token for authorizing requests to RPC providers such as Alchemy
 * and QuickNode. Our node provider JWT has an expiration time of 5 minutes. The hook refreshes
 * the token every 4 minutes. Also, we persist the JWT to localStorage so that it can be used
 * by wagmi without creating a new config object every time the JWT changes.
 *
 * @remarks
 * - Tokens are refreshed every 4 minutes (`refreshInterval: 4 * 60 * 1000`).
 */
export function useNodeProviderToken() {
  const [_, setNodeProviderJwt] = useLocalStorage('node-provider-jwt', '');
  const fetchRefreshToken = async () => {
    const response = await fetch(`/api/get-provider-token`, {
      method: 'POST',
      cache: 'no-store',
      credentials: 'include',
    });
    if (!response.ok) throw new Error(`Failed to refresh token: ${response.statusText}`);
    return await response.json();
  };
  return useSWR<string, Error>('/api/get-provider-token', fetchRefreshToken, {
    refreshInterval: 4 * 60 * 1000,
    refreshWhenHidden: true,
    // Persist the JWT to localStorage so that it can be used by wagmi
    // without creating a new config object every time the JWT changes.
    onSuccess: setNodeProviderJwt,
  });
}

/**
 * Retrieves the node provider JWT from local storage.
 *
 * @returns The node provider JWT.
 * @throws Error - If the JWT is not found in local storage or has expired.
 */
export const getNodeProviderJwt = () => {
  const jwt = window.localStorage.getItem('node-provider-jwt');
  if (!jwt) throw new Error('JWT not found in local storage');
  if (isJwtExpired(jwt)) throw new Error('JWT has expired');
  return jwt.replaceAll('"', '');
};

/**
 * Checks if a JWT is expired.
 *
 * @param token - The JWT string to validate.
 * @returns `true` if the token is expired or invalid, `false` otherwise.
 *
 * Decodes the token to compare its `exp` claim against the current time. Logs an error
 * and returns `true` if the token lacks an `exp` claim or cannot be decoded.
 */
function isJwtExpired(token: string): boolean {
  try {
    const decoded = decodeJwt(token);
    if (!decoded.exp) throw new Error('Token does not have an expiration time.');
    const currentTime = Math.floor(Date.now() / 1000); // Compare `exp` (in seconds) with the current time (in seconds)
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
}
